import React from "react";
import { useTranslation } from "react-i18next";
import SocialLogin from "../common/SocialLogin";

export default function (props) {
  const { t } = useTranslation();
  const { handleEmailLogin, handlePhoneLogin, quickPost, handleLDAPLogin } =
    props;

  return (
    <>
      <button
        className="btn btn-primary email-login"
        onClick={handlePhoneLogin}
      >
        {quickPost ? (
          <>
            <i className="fa fa-mobile" aria-hidden="true"></i>
            {t("Quick Post")}
          </>
        ) : (
          <>
            <i className="fa fa-mobile" aria-hidden="true"></i>
            {t("Login with Mobile")}{" "}
          </>
        )}
      </button>

      <button
        className="btn btn-primary email-login mt-3"
        onClick={handleEmailLogin}
      >
        <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
        {t("Continue with Email")}
      </button>

      <button
        className="btn btn-primary email-login mt-3 d-none"
        onClick={handleLDAPLogin}
      >
        <i className="fa fa-desktop" aria-hidden="true"></i>{" "}
        {t("Continue with LDAP")}
      </button>

      <SocialLogin {...props} />
    </>
  );
}
