import React from "react";
// import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginAction, setUser } from "../../actionCreators";
import { useTranslation } from "react-i18next";
import {
  GoogleOAuthProvider,
  hasGrantedAnyScopeGoogle,
  useGoogleLogin,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { styled } from "styled-components";
import axios from "axios";

const GoogleSignInButton = styled.button`
  width: 100%;
  height: 49px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 3px 20px;
  cursor: pointer;
  transition: all 0.15s;
  outline: none;
  border: 1px solid #cccccc;
  margin-top: 16px;
  &:hover {
    background-color: #e3e3e3;
  }
  p {
    margin: 0;
    font-weight: 700;
    color: #777777;
    font-family: sans-serif;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //LOGGED IN WITH GOOGLE

  const fetchTokenInfo = async (token) => {
    try {
      const res = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching token info:", error);
      return null;
    }
  };
  const WrappedGoogleButton = () => {
    const responseGoogle = async (response) => {
      const decodedResponse = await fetchTokenInfo(response.access_token);

      const result = await Auth.doUserRequest(
        "user_service/register-via-social-media/google",
        {
          name: decodedResponse.name,
          email: decodedResponse.email,
        }
      );
      if (result.success) {
        // toast.success(result.message);
        await Auth.asyncLocalStorage.setItem(
          "user",
          JSON.stringify(result.data)
        );
        await Auth.asyncLocalStorage.setItem(
          "token",
          JSON.stringify(result.token)
        );
        dispatch(loginAction());
        dispatch(setUser(result.data));
        if (props?.close) {
          props.close();
        }
        if (props?.submit) {
          await props.submit(props?.data);
        }
      } else {
        toast.error(t(result.message) || t("Network error"));
      }
    };
    const responseGoogleFailure = (response) => {};
    const login = useGoogleLogin({
      onSuccess: (codeResponse) => responseGoogle(codeResponse),
      onError: (codeResponse) => responseGoogleFailure(codeResponse),
      flow: "implicit",
      scope: "profile",
    });
    return (
      <GoogleSignInButton type="button" onClick={login}>
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="#000" fill-rule="evenodd">
            <path
              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
              fill="#EA4335"
            ></path>
            <path
              d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
              fill="#4285F4"
            ></path>
            <path
              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
              fill="#FBBC05"
            ></path>
            <path
              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
              fill="#34A853"
            ></path>
            <path fill="none" d="M0 0h18v18H0z"></path>
          </g>
        </svg>
        <p>{t("Continue with Google")}</p>
      </GoogleSignInButton>
      // </GoogleOAuthProvider>
    );
  };
  //LOGGED IN WITH FB
  const responseFacebook = async (response) => {
    const result = await Auth.doUserRequest(
      "user_service/register-via-social-media/facebook",
      {
        name: response.name,
        email: response.email,
      }
    );

    if (result.success) {
      await Auth.asyncLocalStorage.setItem("user", JSON.stringify(result.data));
      await Auth.asyncLocalStorage.setItem(
        "token",
        JSON.stringify(result.token)
      );
      dispatch(loginAction());
      dispatch(setUser(result.data));
      if (props?.close) {
        props.close();
      }
      if (props?.submit) {
        await props.submit(props?.data);
      }
    } else {
      toast.error(t(result.message) || t("Network error"));
    }
  };
  //Handle failure response
  const responseFacebookFailure = (response) => {};

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <span title={t("Continue with Google")}>
        <WrappedGoogleButton />
        {/* <GoogleSingInButton>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#000" fill-rule="evenodd">
              <path
                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                fill="#EA4335"
              ></path>
              <path
                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                fill="#4285F4"
              ></path>
              <path
                d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                fill="#FBBC05"
              ></path>
              <path
                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                fill="#34A853"
              ></path>
              <path fill="none" d="M0 0h18v18H0z"></path>
            </g>
          </svg>
          <p>{t("Continue with Google")}</p>
        </GoogleSingInButton> */}

        {/* <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText={t("Continue with Google")}
          onSuccess={responseGoogle}
          onFailure={responseGoogleFailure}
          cookiePolicy={"single_host_origin"}
          disabledStyle
          disabled={props.disabled}
          className="google-login mt-3"
        /> */}
      </span>

      <span title={t("Continue with Facebook")}>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_ID}
          fields="name,email"
          // onClick={componentClicked}
          isDisabled={props.disabled}
          icon="fa fa-facebook"
          textButton={t("Continue with Facebook")}
          callback={responseFacebook}
          onFailure={responseFacebookFailure}
          cssClass="fb connect mt-3"
          size="medium"
          style={{
            width: "100%",
          }}
        />
      </span>
    </GoogleOAuthProvider>
  );
}
