import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useSettingsUIContext } from "../../SettingUIContext";
import { useTranslation } from "react-i18next";
import LanguagesDropdown from "./dropdown/LanguagesDropdown";
import { shallowEqual, useSelector } from "react-redux";
import HrefLang from "../common/HrefLang";

export default function Footer() {
  const settingsUIContext = useSettingsUIContext();
  const { categories, cmsSettings, settings } = useSelector(
    (state) => ({
      categories: state.categories,
      cmsSettings: state.cmsSettings,
      settings: state.settings,
    }),
    shallowEqual
  );

  const settingsUIProps = useMemo(() => {
    return {
      show_mobile_app_icon: settingsUIContext.show_mobile_app_icon,
      show_social_icons_footer: settingsUIContext.show_social_icons_footer,
      android_mobile_app_url: settingsUIContext.android_mobile_app_url,
    };
  }, [settingsUIContext]);
  const { t } = useTranslation();
  let popularCategories = [];
  let categoriesSlugs = [];
  let categoriesData = [];
  categories.map((category) => {
    if (category.mark_as_popular) {
      categoriesData.push(category);
      popularCategories.push(category.title);
      categoriesSlugs.push(category.slug);
    }
    if (category.catChilds) {
      category.catChilds.map((catChild) => {
        if (catChild.mark_as_popular) {
          categoriesData.push(catChild);
          popularCategories.push(catChild.title);
          categoriesSlugs.push(catChild.slug);
        }
      });
    }
  });

  return (
    <React.Fragment>
      <HrefLang />
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-5 order-md-1">
              <div className="widget">
                <div className="reach">
                  <h4>{t(settings?.column1_title)}</h4>
                  <ul className="address">
                    <li>
                      <i
                        className="fa fa-location-arrow"
                        aria-hidden="true"
                      ></i>
                      <a
                        title={settings.Address}
                        href={settings?.address_map}
                        target="_blank"
                      >
                        {settings.Address}
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <a
                        title={settings.Telephone}
                        href={`tel:${settings.Telephone}`}
                      >
                        {settings.Telephone}
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      <a
                        title={settings.Email}
                        href={`mailto:${settings.Email}`}
                      >
                        {settings.Email}
                      </a>
                    </li>
                  </ul>
                  {settingsUIProps.show_social_icons_footer && (
                    <ul className="social-media">
                      {settings?.Facebook && (
                        <li>
                          <a
                            title={t("Facebook")}
                            href={settings.Facebook}
                            target="_blank"
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      )}

                      {settings?.Twitter && (
                        <li>
                          <a
                            title={t("Twitter")}
                            href={settings.Twitter}
                            target="_blank"
                          >
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                      )}
                      {settings?.Instagram && (
                        <li>
                          <a
                            title={t("Instagram")}
                            target="_blank"
                            href={settings.Instagram}
                          >
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      )}
                      {settings?.Linkedin && (
                        <li>
                          <a
                            title={t("LinkedIn")}
                            href={settings.Linkedin}
                            target="_blank"
                          >
                            <i
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
                <div className="we-accept d-none">
                  <h4>{t("We accept for payment:")}</h4>
                  <ul className="accept">
                    <li>
                      <img src="/images/Master.png" alt="" />
                    </li>
                    <li>
                      <img src="/images/Visa.png" alt="" />
                    </li>
                    <li>
                      <img src="/images/Blue.png" alt="" />
                    </li>
                    <li>
                      <img src="/images/Discover.png" alt="" />
                    </li>
                    <li>
                      <img src="/images/Bank.png" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-12 order-md-2 ml-xl-auto mr-xl-5 mt-lg-0 my-md-4">
              <div className="row">
                <div className="col-xl-3 col-md-6 col-sm-6 px-lg-auto px-md-0 order-xl-1 order-md-4  order-sm-3 ">
                  <div className="widget">
                    <h4>{t(settings?.column2_title)}</h4>
                    <ul className="indcate">
                      {popularCategories.map((category, index) => (
                        <li key={index}>
                          <Link
                            title={t(category)}
                            to={{
                              pathname: `/category/${categoriesSlugs[index]}`,
                            }}
                            state={{
                              category: categoriesData[index],
                              prevPath: window.location.pathname,
                            }}
                          >
                            {t(category)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-6 px-lg-auto px-md-0 order-xl-2 order-md-1  order-sm-1 ">
                  <div className="widget">
                    <h4>{t(settings?.column3_title)}</h4>
                    <ul className="indcate">
                      <li>
                        <Link title={t("Upload a Cool Photo")} to="/post-ad">
                          {t("Upload a Cool Photo")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          title={t("Add Proper Descriptions")}
                          to="/post-ad"
                        >
                          {t("Add Proper Descriptions")}
                        </Link>
                      </li>
                      <li>
                        <Link title={t("Set Appropriate Price")} to="/post-ad">
                          {t("Set Appropriate Price")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-6 px-lg-auto px-md-0 order-xl-3 order-md-2  order-sm-2 ">
                  <div className="widget">
                    <h4>{t(settings?.column4_title)}</h4>
                    <ul className="indcate">
                      {cmsSettings &&
                        cmsSettings.map((cmsPage, index) => {
                          if (cmsPage.footer_column === 4) {
                            return (
                              <li key={index}>
                                <Link
                                  title={t(cmsPage.title)}
                                  to={`/page/${cmsPage.slug}`}
                                >
                                  {t(cmsPage.title)}
                                </Link>
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-sm-6 px-lg-auto px-md-0 order-xl-4 order-md-4  order-sm-4 ">
                  <div className="widget">
                    <h4>{t(settings?.column5_title)}</h4>
                    <ul className="indcate">
                      {cmsSettings &&
                        cmsSettings.map((cmsPage, index) => {
                          if (cmsPage.footer_column === 5) {
                            return (
                              <li key={index}>
                                <Link
                                  title={t(cmsPage.title)}
                                  to={`/page/${cmsPage.slug}`}
                                >
                                  {t(cmsPage.title)}
                                </Link>
                              </li>
                            );
                          }
                        })}
                      <li>
                        <Link title={t("Contact Us")} to="/contact-us">
                          {t("Contact Us")}
                        </Link>
                      </li>
                    </ul>
                    {settingsUIProps.show_mobile_app_icon && (
                      <a
                        title={t("Download Mobile app")}
                        href={settingsUIProps.android_mobile_app_url}
                        className="d-flex"
                      >
                        <img
                          src="/images/products/downloadmobileappbanner.jpg"
                          className="img-fluid"
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 order-md-3">
              <div className="footer-bottom">
                <div className="copyright">
                  <p>
                    {t("Copyright")} © {t(`${new Date().getFullYear()}`)},{" "}
                    {t(`${settings?.website_title}. All Rights Reserved.`)}
                  </p>
                </div>
                <div className="languageselector">
                  <LanguagesDropdown mainComponent="footer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
