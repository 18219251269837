// import { takeEvery, takeLatest, throttle } from "redux-saga";
import { call, put } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators';
import Service from '../services/UserService';

export function* getUnreadMessages() {
  try {
    if (localStorage.getItem('token')) {
      let messages = yield call(() =>
        Service.Request('chat_service/unread-messages', '', 'GET').then(
          (response) => {
            return response.data;
          }
        )
      );
      yield put(actionCreators.getUnreadMessages(messages));
    }
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
