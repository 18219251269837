import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { prepareFiltersURL, getRouteParams } from "../../services/Helpers";
import "./dropDown.css";
import { setSelectedCategory } from "../../actionCreators";

const DropDown = () => {
  const { categories, selectedCategory, settings } = useSelector(
    (state) => ({
      selectedCategory: state.selectedCategory,
      categories: state.categories,
      settings: state.settings,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function slideSubCategories(e) {
    e.target.parentElement.classList.toggle("sub-show");
    e.target.parentElement.children[2].classList.toggle("subCategoryHide");
  }

  const categoryLabelTranslations = (category) => {
    if (category?.translation?.length > 0) {
      return category.translation[0]?.labelTranslation;
    }
    return category?.title;
  };

  function moreLessText(e) {
    e.target.parentElement.parentElement.classList.toggle("exceed");
    if (e.target.textContent === t("More")) {
      e.target.textContent = t("Less");
    } else {
      e.target.textContent = t("More");
    }
  }

  const categoryChanged = (category, close = false) => {
    const _cat = category;
    const cat_slug = category?.slug ? category?.slug : "all";
    let _filterUrl = `/category/${cat_slug}${window.location.search}`;
    navigate(_filterUrl);
    // document.getElementById("megamenupopup").style.display = "none";
    dispatch(setSelectedCategory(category));
    if (close) {
      setShowMenu(false);
    } else {
      toggleCategoryMenu();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const categoryListing = categories.map((category, index) => {
    return (
      <li className="haveChild" key={index}>
        {category.catChilds.length > 0 && (
          <i className="fa fa-caret-down" onClick={slideSubCategories}></i>
        )}
        <a
          title={t(categoryLabelTranslations(category))}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            categoryChanged(category);
          }}
        >
          <span
            style={{
              color:
                (selectedCategory.slug == category.slug ||
                  selectedCategory.parent_category == category.id) &&
                `${settings.color}`,
            }}
          >
            {categoryLabelTranslations(category)}
          </span>
        </a>
        {category.catChilds.length > 0 && (
          <div className="megamenu-sub exceed">
            <ul className="megamenu-sub-list">
              {category.catChilds.map((childCategory, i) => {
                return (
                  <li className={""} key={i}>
                    <a
                      title={categoryLabelTranslations(childCategory)}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        categoryChanged(childCategory);
                      }}
                    >
                      <span
                        style={{
                          color:
                            selectedCategory.slug == childCategory.slug &&
                            `${settings.color}`,
                        }}
                      >
                        {" "}
                        {categoryLabelTranslations(childCategory)}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
            {category.catChilds.length > 5 && (
              <div className="lessmore-main">
                {
                  <a
                    href="#"
                    className="dropdownmore"
                    onClick={(e) => {
                      e.preventDefault();
                      moreLessText(e);
                    }}
                  >
                    {t("More")}
                  </a>
                }
              </div>
            )}
          </div>
        )}
      </li>
    );
  });

  function toggleCategoryMenu(event) {
    setShowMenu((showMenu) => !showMenu);
  }

  return (
    <React.Fragment>
      <div className="top-header-megamenu" ref={menuRef}>
        <div className="megamenu megamenu-head">
          <div className="selected">
            <span title={t("All Categories")} onClick={toggleCategoryMenu}>
              {selectedCategory === "all"
                ? t("All Categories")
                : categoryLabelTranslations(selectedCategory)}
            </span>
            <div className="icons">
              {selectedCategory !== "all" ? (
                <svg   onClick={(e) => categoryChanged("all", true)} class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                
              ) : null}
              <i className="fa fa-caret-down" onClick={toggleCategoryMenu}></i>
            </div>
          </div>
        </div>
        {showMenu && (
          <div className="megamenu pop-up" id="megamenupopup">
            <div className="megamenu-content-tree">
              <ul>{categoryListing}</ul>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DropDown;
