import * as actionTypes from "./../actionTypes";
const settings = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_SETTINGS:
      return action.settings;
    default:
      return state;
  }
};

export default settings;
