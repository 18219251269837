import React from "react";
import { useParams } from "react-router-dom";
import DetailPage from "./detailPage";

function App(props) {
  const { slug } = useParams();
  return (
    <React.Fragment>
      <DetailPage slug={slug} {...props} />
    </React.Fragment>
  );
}

export default App;
