import * as actionTypes from "../actionTypes";
const bannerAd = (state = [], action) => {
  switch (action.type) {
    case actionTypes.BANNER_ADS:
      return action.bannerAds;
    default:
      return state;
  }
};

export default bannerAd;
