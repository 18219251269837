import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { prepareFiltersURL, getRouteParams } from "../../services/Helpers";

export default function Breadcrumb({ item }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { breadCrumbs, categories } = useSelector(
    (state) => ({
      categories: state.categories,
      breadCrumbs: state.breadcrumbs,
    }),
    shallowEqual
  );
  if (!item) item = breadCrumbs;

  const handleClick = (slug) => {
    if (slug.includes("category")) {
      let slugArray = slug?.split("/");
      let _filterUrl = `/category/${slugArray[2]}${prepareFiltersURL(
        [],
        true
      )}`;
      let _cat = "all";

      if (slugArray[2]) {
        categories.map((category) => {
          let filteredCat = [];
          if (category.catChilds && category.catChilds.length > 0) {
            filteredCat = category.catChilds.map((childCategory) => {
              if (childCategory.slug === slugArray[2]) {
                _cat = childCategory;
                return childCategory;
              }
            });
          }
          if (category.slug === slugArray[2]) {
            _cat = category;
            return category;
          }
          return filteredCat;
        });
      }
      const paramsObject = getRouteParams(_filterUrl, _cat);
      navigate(paramsObject);
    } else {
      navigate(slug);
    }
  };
  return (
    <Breadcrumbs aria-label="breadcrumb" className="breadCrumb">
      {item.map((list, index) => {
        const isLast = index === item.length - 1;
        if (isLast) {
          return <Typography key={index}>{list.title}</Typography>;
        }
        return (
          <a
            onClick={() => {
              handleClick(list?.url);
            }}
            className="anchorTag"
            key={index}
          >
            {list.title === "Home" ? t(list?.title) : list.title}
          </a>
        );
      })}
    </Breadcrumbs>
  );
}
