import * as actionTypes from "./../actionTypes";
const packages = (state = [], action) => {
  switch (action.type) {
    case actionTypes.ADD_PACKAGE:
      const packagesArray = state;
      packagesArray.push(action.package);
      return Array.isArray(action.package) ? action.package : packagesArray;
    case actionTypes.REMOVE_PACKAGE:
      return state.filter((_package) => _package._id !== action.package._id);
    case actionTypes.REMOVE_ALL_PACKAGES:
      return [];
    default:
      return state;
  }
};

export default packages;
