import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { logoutAction } from "../../actionCreators";
import { Navigate } from "react-router-dom";

export default function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoutAction());
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.reload(false);
  });
  return <Navigate to="/login" />;
}
