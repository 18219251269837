import * as actionTypes from "../actionTypes";
const category = (state = "all", action) => {
  switch (action.type) {
    case actionTypes.SELECTED_CATEGORY:
      return action.category;
    default:
      return state;
  }
};

export default category;
