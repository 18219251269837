import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Footer from "./components/Includes/footer";
import Navbar from "./components/Includes/navbar";
// import Sidebar from "./components/Includes/sidebar";
import Home from "./components/Home";
import Product from "./components/Product";
import Detail from "./components/Details";
import Profile from "./components/Profile";
import Login from "./components/Auth";
import Register from "./components/Register";
import Logout from "./components/Auth/logout";
import AddProduct from "./components/Product/add";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SecuredRoutes from "./components/SecureRoute";
import RouteBeforeLogin from "./components/SecureRoute/beforeLogin";
import ForgetPass from "./components/Auth/Forget/forget";
import RenewPass from "./components/Auth/Forget/renew";
import NotFound from "./components/404";
import EditProduct from "./components/Product/edit";
import Setting from "./components/Profile/SettingsModule";
import PublicProfile from "./components/Profile/Public";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import ChatModule from "./components/Chat";
import ProductSuccess from "./components/Product/postSubmitted";
import ContactUs from "./components/ContactUs/contactUs";
import WebNameSuggestion from "./components/NameSuggestion/NameSuggestion";
import moment from "moment";
import AddOption from "./components/AdsPackages/addOptions";
import DiscountPackages from "./components/AdsPackages/discountPackages";
import ViewCart from "./components/AdsPackages/viewcart";
import { getLocale } from "./services/Helpers";
import { CMSPages } from "./components/Pages/CMSPages";
import PurchasePackages from "./components/Profile/PurchasePackages";

function App() {
  const [languageDirection, setLanguageDirection] = useState("ltr");
  const languages = useSelector((state) => state.languages, shallowEqual);

  // const { languages } =
  //   useSelector(
  //     (state) => ({
  //       languages: state.languages,
  //     }),
  //     shallowEqual
  //   );
  let dir = "";
  useEffect(() => {
    if (languages?.length > 0) {
      const locale = localStorage.getItem("defaultLocale");
      dir = languages.find((l) => l.defaultLocale === locale)?.direction;
      if (dir) {
        document.documentElement.setAttribute("dir", dir);
      }
      setLanguageDirection(dir);
    }
  }, [languages]);
  moment.locale(getLocale());
  return (
    <React.Fragment>
      <ToastContainer
        position={
          languageDirection && languageDirection == "rtl"
            ? "top-left"
            : "top-right"
        }
        theme="colored"
      />
      <div className="main-wrapper">
        <ThemeProvider theme={theme}>
          {/* <Sidebar /> */}
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/category/:category_slug" element={<Product />} />
            <Route path="/product/:slug" element={<Detail />} />
            <Route element={<SecuredRoutes />}>
              <Route path="/package/options" element={<AddOption />} />
              <Route path="/choose/packages" element={<DiscountPackages />} />
              <Route
                path="/purchased/packages"
                element={<PurchasePackages />}
              />
              <Route path="/view/cart" element={<ViewCart />} />
              <Route path="/chat" element={<ChatModule />} />
              <Route path="/profile/:favorites?" element={<Profile />} />
              <Route path="/ad-posted" element={<ProductSuccess />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/edit-ad/:slug" element={<EditProduct />} />
              <Route path="/logout" element={<Logout />} />
            </Route>
            <Route path="/forget-password" element={<ForgetPass />} />
            <Route path="/reset-password/:token" element={<RenewPass />} />
            <Route element={<RouteBeforeLogin />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Route>
            <Route path="/user-profile/:userId" element={<PublicProfile />} />
            <Route path="/post-ad" element={<AddProduct />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/name-suggestion" element={<WebNameSuggestion />} />
            <Route path="/page/:slug" element={<CMSPages />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

export default App;
