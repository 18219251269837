import React from "react";
import { TextField, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MapContainer from "./googleMaps";
import RangeSlider from "./rangeSlider";

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-53%, -50%)`,
  };
}

const FilterModal = () => {
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle}>
      <h2 id="simple-modal-title">
        {" "}
        <CloseIcon /> Change Location
      </h2>
      <TextField fullWidth placeholder="New York, NY, USA" />
      <div>
        <MapContainer />
      </div>
      <RangeSlider />
    </div>
  );
  return (
    <div>
      <label onClick={handleOpen}>
        {" "}
        Location <i className="fa fa-sort-desc" aria-hidden="true"></i>
      </label>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default FilterModal;
