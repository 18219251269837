import Service from "./UserService";
import millify from "millify";

export function prepareFiltersURL(newEntries = [], categoryChanged = false) {
  let _queryVars = getAllQuery();
  let _filterUrl = "";

  if (_queryVars.length == 0 && newEntries.length == 0) return "";

  if (_queryVars.length > 0) {
    _queryVars.forEach((value, i) => {
      let _value = value[1];

      newEntries.forEach((entry, i) => {
        let _entryArr = Object.entries(entry)[0];

        if (_entryArr[0] == value[0]) {
          _value = _entryArr[1];
          newEntries.splice(i, 1);
        }
      });
      if (categoryChanged && value[0].includes("attr")) {
        _filterUrl += "";
      } else {
        _filterUrl += `${value[0]}=${encodeURIComponent(_value)}${
          _queryVars.length - 1 == i ? "" : "&"
        }`;
      }
    });
  }

  // We are applying "_addNewEntry" check because this entry might be already available
  // in url and will be updated in above query data traverse, so there is no need to add new
  if (newEntries.length > 0) {
    newEntries.forEach((entry) => {
      let _entryArr = Object.entries(entry)[0];

      _filterUrl += `&${_entryArr[0]}=${encodeURIComponent(_entryArr[1])}`;
    });
  }

  _filterUrl = _filterUrl.replace("?&", "?");

  return _filterUrl;
}

export function getAllQuery(returnObject = false) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");

  let _queryVars = [];
  let _queryVarObj = {};

  if (vars.length > 0) {
    for (var i = 0; i < vars.length; i++) {
      let _varsData = vars[i].split("=");
      let _title = _varsData[0];
      let _value = _varsData[1];

      if (_title && _title != "") {
        _queryVars.push(_varsData);
        _queryVarObj[_title] = _value;
      }
    }
  }

  if (_queryVarObj["search"]) {
    delete _queryVarObj.search;
  }

  return returnObject ? _queryVarObj : _queryVars;
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);

  if (!query) return "";

  // query = decodeURIComponent(query);

  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return "";
}

export function toJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export function getRouteParams(_filterUrl, _cat, values = {}) {
  const { name, value } = values;
  let _queryParams = getAllQuery(true);
  let _routeParams = {
    ..._queryParams,

    // searchInput: _queryParams["search"],

    state: {
      price: { min: _queryParams["min"], max: _queryParams["max"] },
      prevPath: window.location.pathname,
    },
  };

  if (typeof _filterUrl === "string") {
    _routeParams["pathname"] = _filterUrl;
  } else {
    _routeParams = { ..._routeParams, ..._filterUrl };
  }

  if (_cat !== "all") {
    _routeParams["state"]["category"] = _cat;
  } else {
    _routeParams["slug"] = "all";
  }

  if (values?.name && values?.value)
    _routeParams[name] = encodeURIComponent(value);

  return _routeParams;
}

export function getSelectedCategory(categories, slug) {
  let categoryObject;
  categories.map((category) => {
    if (category.catChilds && category.catChilds.length > 0) {
      category.catChilds.map((childCategory) => {
        if (childCategory.slug === slug) {
          categoryObject = childCategory;
        }
      });
    }
    if (category.slug === slug) {
      categoryObject = category;
      return category;
    }
  });

  return categoryObject;
}
export const getDefaultLocale = async () => {
  let locale;
  const { data } = await Service.Request(
    "setting_service/listselectedtranslations",
    "",
    "GET"
  );
  locale = data[0].translation[0].locale;
  return locale;
};

export const verifyLocale = (locale) => {
  locale = locale || window.location.pathname.split("/")[1];
  const languages = getParsedLocalStorageData("langaugeLocales") || [];
  return languages.filter((language) => locale === language);
};
export const getParsedLocalStorageData = (field) => {
  try {
    const dataToParse = localStorage.getItem(field);
    if (dataToParse && typeof dataToParse === "string") {
      return JSON.parse(dataToParse);
    }
    return dataToParse || null;
  } catch (e) {
    if (field === "user" || field === "token") {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    if (field === "translations") {
      return {};
    }
    return null;
  }
};

export const getLocale = (url = window.location.pathname) => {
  const locale = url.replace(/[?#].*$/, "").split("/")[1];
  if (!locale || locale === undefined) return "en-gb";
  const isoTwoCode = locale.split("_");
  try {
    if (isoTwoCode[0] == "zh") {
      const chineseLocale = locale.replace("_", "-").toLowerCase();
      require(`../../node_modules/moment/locale/${chineseLocale}`);
      return chineseLocale;
    } else if (locale == "en_US") {
      return "en-gb";
    }
    require(`../../node_modules/moment/locale/${isoTwoCode[0]}`);
    return isoTwoCode[0].toLowerCase();
  } catch (err) {
    return "en-gb";
  }
};
export const fetchDefaultLocale = () => {
  const locale = localStorage.getItem("defaultLocale");
  return locale;
};
export const inputValidation = (type, max = 0) => {
  let _inputValidation;
  switch (type) {
    case "numeric":
      _inputValidation = (e) => {
        if (max > 0) {
          e.target.value = e.target.value
            .replace(/[^0-9\s]/, "")
            .slice(0, max)
            .trim();
        } else {
          e.target.value = e.target.value.replace(/[^0-9\s]/, "").trim();
        }
      };
      break;
    case "alphabetic":
      _inputValidation = (e) => {
        e.target.value = e.target.value.replace(/[^A-Za-z\s]/, "");
      };
      break;
    case "alpha-numeric":
      _inputValidation = (e) => {
        e.target.value = e.target.value.replace(/[^A-Za-z0-9\s]/, "");
      };
      break;

    default:
      _inputValidation = (e) => {
        e.target.value = e.target.value.toString().replace(/^\s/g, "");
      };
      break;
  }
  return _inputValidation;
};

export const categoryLabelTranslations = (category) => {
  if (category?.translation?.length > 0) {
    return category.translation[0]?.labelTranslation;
  }
  return category?.title;
};

export const locationLabelTranslations = (
  location,
  locationTranslation,
  field
) => {
  if (locationTranslation?.length > 0) {
    return locationTranslation[0]?.labelTranslation;
  }
  return location?.[field];
};

export const currencyLabel = (label) => {
  return label;
};

export const urduPricing = (productPrice, t, setting, locations) => {
  let formatter = new Intl.NumberFormat("en-US", {});
  let price = Math.abs(productPrice);
  if (price < 100000) {
    return (
      formatter.format(productPrice) +
      "  " +
      t(currencyLabel(setting?.default_currency))
    );
  }
  if (price >= 100000 && price.toString().length <= 7) {
    return (price =
      (price / 100000).toFixed(2) +
      " " +
      t("Lakh") +
      " " +
      t(currencyLabel(locations?.currencySymbol || setting?.default_currency)));
  } else if (price >= 10000000 && price.toString().length <= 9) {
    return (price =
      (price / 10000000).toFixed(2) +
      " " +
      t("Crore") +
      " " +
      t(currencyLabel(locations?.currencySymbol || setting?.default_currency)));
  } else if (price >= 1000000000 && price.toString().length <= 11) {
    return (price =
      (price / 1000000000).toFixed(2) +
      " " +
      t("Arab") +
      " " +
      t(currencyLabel(locations?.currencySymbol || setting?.default_currency)));
  } else if (price >= 100000000000) {
    return (price =
      (price / 100000000000).toFixed(2) +
      " " +
      t("Kharab") +
      " " +
      t(currencyLabel(locations?.currencySymbol || setting?.default_currency)));
  }
};

export const standardPricing = (productPrice, t, setting, locations) => {
  let formatter = new Intl.NumberFormat("en-US", {});
  const price = millify(productPrice, {
    precision: 2,
  });

  switch (price.slice(-1)) {
    case "M":
    case "B":
    case "T":
      return (
        t(
          currencyLabel(locations?.currencySymbol || setting?.default_currency)
        ) +
        " " +
        price
      );
    default:
      return (
        t(
          currencyLabel(locations?.currencySymbol || setting?.default_currency)
        ) +
        " " +
        formatter.format(productPrice)
      );
  }
};

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
