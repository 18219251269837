import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

export default function HrefLang() {
  const activeLanguages = useSelector((state) => state.languages);
  let languages = [];
  let lang = "";
  const defaultLocale = localStorage.getItem("defaultLocale");
  if (defaultLocale) {
    lang = defaultLocale.replace("_", "-");
  }

  activeLanguages.map((activeLanguage, index) => {
    // if (activeLanguage.defaultLocale === defaultLocale) {
    //   dir = activeLanguage.direction;
    // }
    languages[index] = activeLanguage.defaultLocale;
  });
  return (
    <Helmet>
      <html lang={lang} />
      {languages &&
        languages.map((language, index) => {
          return (
            <link
              key={index}
              rel="alternate"
              hreflang={language}
              href={`${process.env.REACT_APP_URL}/${language}`}
            />
          );
        })}
    </Helmet>
  );
}
