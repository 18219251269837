import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ProductsModule from "../Includes/ProductsModule";
export default function ({
  similarProducts,
  topProducts,
  userProducts,
  title,
}) {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
      <Tab eventKey="home" title={t("Related")}>
        <section className="you-will-like featured-products pt-0">
          <div className="container-fluid px-sm-0">
            {similarProducts.length > 0 ? (
              <ProductsModule products={similarProducts} />
            ) : (
              <div className="no-product">
                <h6>{t("No More Products")}</h6>
              </div>
            )}
          </div>
        </section>
      </Tab>
      <Tab eventKey="profile" title={t("Top Products")}>
        <section className="you-will-like featured-products pt-0">
          <div className="container-fluid px-sm-0">
            {topProducts.length > 0 ? (
              <ProductsModule products={topProducts} />
            ) : (
              <div className="no-product">
                <h6>{t("No more user posted product")}</h6>
              </div>
            )}
          </div>
        </section>
      </Tab>

      <Tab eventKey="user" title={`${t("Posted by")} ${title}`}>
        <section className="you-will-like featured-products pt-0">
          <div className="container-fluid">
            {userProducts.length > 0 ? (
              <ProductsModule products={userProducts} />
            ) : (
              <div className="no-product">
                <h6>{t("No more user posted product")}</h6>
              </div>
            )}
          </div>
        </section>
      </Tab>
    </Tabs>
  );
}
