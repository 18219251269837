import axios from "axios";
import { getParsedLocalStorageData } from "./Helpers";
class AuthService {
  token = getParsedLocalStorageData("token") || "";
  async doUserRequest(URL, data) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + URL,
        data
      );
      return response.data;
    } catch (e) {
      if (e.response) {
        return e.response.data;
      } else {
        return { success: false, data: [] };
      }
    }
  }

  getLocale(url = window.location.pathname) {
    const locale = url.replace(/[?#].*$/, "").split("/")[1];
    return locale ? locale : "en_US";
  }

  async UploadImage(URL, data, method, token) {
    try {
      token = getParsedLocalStorageData("token") || "";
      const response = await axios({
        method: method,
        url: process.env.REACT_APP_BASE_URL + URL,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
        data: data,
      });
      return response.data;
    } catch (e) {
      if (e.response) {
        return e.response.data;
      } else {
        return { success: false, data: [] };
      }
    }
  }

  Request = async (URL, data, method) => {
    let token = getParsedLocalStorageData("token") || "";
    let languageLocale = this.getLocale(window.location.pathname);
    let paramObject = method.toLowerCase() == "get" ? { languageLocale } : null;

    try {
      const response = await axios({
        method: method,
        url: process.env.REACT_APP_BASE_URL + URL,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        params: paramObject,
        data: data,
      });
      return response.data;
    } catch (e) {
      if (e.response) {
        if (e.response?.data?.expired) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload(false);
        }
        return e.response.data;
      } else {
        return {
          success: false,
          message: "Something went wrong",
          error: e.message,
          data: [],
        };
      }
    }
  };

  Logout = async () => {
    let token = getParsedLocalStorageData("token") || "";
    return axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + "user_service/logout",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
      data: {},
    });
  };

  asyncLocalStorage = {
    setItem: async function (key, value) {
      await null;
      return localStorage.setItem(key, value);
    },
    getItem: async function (key) {
      await null;
      return JSON.parse(localStorage.getItem(key));
    },
  };

  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return "";
  };

  getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
}
export default new AuthService();
