import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import NavTabs from "./Tabs";
import MetaTitle from "../../common/MetaTitle";

export default function PurchasePackages() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <MetaTitle title="Purchased Packages" />
      <section className="my-account-main site-main profile-imac-padding">
        <Grid container className="my-account">
          <div className="row m-auto w-100">
            <div className="col-lg-12">
              <div className="my-account-inner">
                <div className="left">
                  <h4 className="mb-0">{t("Purchased Packages")}</h4>
                </div>
                <Link
                  className="btn btn-primary"
                  style={{ padding: "0.375rem 0.75rem" }}
                  to="/profile"
                >
                  {t("Back to Profile")} &nbsp;{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-12">
              <NavTabs />
            </div>
          </div>
        </Grid>
      </section>
    </React.Fragment>
  );
}
