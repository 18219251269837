import React from "react";
import { useSelector } from "react-redux";

export const BannerAd = ({ location }) => {
  const { REACT_APP_IMAGE_URL } = process.env;
  const bannerAds = useSelector((state) => state.bannerAd);
  return (
    <>
      {bannerAds && (
        <div className="bannerads">
          {bannerAds.map((banner, index) => {
            if (banner.location === location) {
              if (banner.adType === "image") {
                return (
                  <div className="bannerad-item" key={index}>
                    <img
                      src={REACT_APP_IMAGE_URL + banner?.image}
                      alt={banner?.title}
                      key={index}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: banner?.embedCode }}
                  ></div>
                );
              }
            }
          })}
        </div>
      )}
    </>
  );
};
