import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslation } from "react-i18next";

export default function App(props) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const password = useRef({});
  password.current = watch("password", "");

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDisabledButton = () => setDisabled((disabled) => !disabled);

  const onSubmit = async (data) => {
    handleDisabledButton();
    const response = await Auth.doUserRequest("user_service/register", {
      name: data.name,
      email: data.email,
      password: data.password,
    });
    if (response.success) {
      toast.success(t(response.message));
      props.showform();
    } else {
      toast.error(t(response.message) || t("Network error"));
    }
    setTimeout(() => {
      handleDisabledButton();
    }, 2000);
  };

  return (
    <React.Fragment>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          props.showform();
        }}
      >
        <i
          className="fa fa-arrow-left"
          style={{ marginBottom: "20px", fontSize: "20px" }}
        ></i>
      </a>
      <h5>{t("Register Here")}</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <TextField
            type="text"
            id="inputName"
            className="form-control"
            variant="outlined"
            placeholder={t("Full Name")}
            // name="name"
            label={t("Full Name")}
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value
                .trimStart()
                .replace(/[^a-z\s]/gi, "")
                .toString()
                .slice(0, 30);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            // inputRef={register({
            //   required: t("Name is required"),
            //   minLength: {
            //     value: 3,
            //     message: t("Name must be 3 characters long"),
            //   },
            //   maxLength: 30,
            // })}
            {...register("name")}
            {...props}
          />
          {errors?.name && errors?.name?.message !== "" ? (
            <span className="error-message-ag">{errors?.name?.message}</span>
          ) : null}
        </div>
        <div className="form-group">
          <TextField
            type="text"
            id="inputEmail"
            label={t("Email")}
            variant="outlined"
            fullWidth
            className="form-control"
            placeholder={t("Email address")}
            // name="email"
            InputLabelProps={{
              shrink: true,
            }}
            // inputRef={register({
            //   required: t("Email is required"),
            //   pattern: {
            //     value:
            //       /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            //     message: t("Invalid email"),
            //   },
            // })}
            {...register("email")}
            {...props}
          />
          {errors?.email && errors?.email?.message !== "" ? (
            <span className="error-message-ag">{errors.email.message}</span>
          ) : null}
        </div>
        <div className="form-group">
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink={true} htmlFor="outlined-adornment-password">
              {`${t("Password")}`}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              // value={values.password}
              placeholder={t("Password")}
              variant="outlined"
              label={`${t("Password")}`}
              fullWidth
              notched={true}
              name="password"
              InputLabelProps={{
                shrink: true,
              }}
              // inputRef={register({
              //   required: t("Password is required"),
              //   pattern: {
              //     value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
              //     message: t(
              //       "Password must contain uppercase and lowercase letters and at least one number"
              //     ),
              //   },
              //   minLength: {
              //     value: 8,
              //     message: t("Password must be 8 characters long"),
              //   },
              // })}
              {...register("password")}
              {...props}
              // onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          {errors.password && errors.password.message !== "" ? (
            <span className="error-message-ag">{errors.password.message}</span>
          ) : null}
        </div>
        <div className="form-group">
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink={true} htmlFor="outlined-adornment-showPassword">
              {t("Confirm password")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-showPassword"
              type={values.showConfirmPassword ? "text" : "password"}
              // value={confirmPassword}
              notched={true}
              variant="outlined"
              label={t("Confirm password")}
              placeholder={t("Confirm password")}
              // name="password_repeat"
              inputlabelprops={{
                shrink: true,
              }}
              // onChange={(e) => setConfirmPassword(e.target.value)}
              // inputRef={register({
              //   validate: (value) =>
              //     value === password.current || t("Passwords do not match"),
              // })}
              {...register("password_repeat")}
              {...props}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          {errors.password_repeat && errors.password_repeat.message !== "" ? (
            <span className="error-message-ag">
              {errors.password_repeat.message}
            </span>
          ) : null}
        </div>

        <div className="form-row form-group"></div>
        <button type="submit" className="btn btn-primary" disabled={disabled}>
          {t("Register")} <i className="fa fa-angle-right"></i>
        </button>
        <div className="create-new-account">
          <p>{t("Already have an account then")} </p>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.showform();
            }}
          >
            <i className="fa fa-key" aria-hidden="true"></i> {t("Login Here")}
          </a>
        </div>
      </form>
    </React.Fragment>
  );
}
