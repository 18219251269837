import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";
import { verifyLocale, getParsedLocalStorageData } from "../services/Helpers";
// import i18next from "i18next";
export function* getTranslations() {
  try {
    let languageTranslations = {};
    let defaultLocale;
    const paramLocale = Service.getLocale();
    let userLanguage = paramLocale
      ? paramLocale
      : localStorage.getItem("fallbackLanguage");
    let translations = yield call(() =>
      Service.Request(
        `setting_service/listselectedtranslations?locale=${userLanguage}`,
        "",
        "GET"
      ).then(async (response) => {
        if (response.success) {
          if (response.languageDirection) {
            localStorage.setItem("dir", response.languageDirection);
            document.documentElement.setAttribute(
              "dir",
              response.languageDirection
            );
          } else {
            localStorage.setItem("dir", "ltr");
            document.documentElement.setAttribute("dir", "ltr");
          }
          languageTranslations = response?.data;

          defaultLocale =
            userLanguage && verifyLocale(userLanguage).length > 0
              ? userLanguage
              : localStorage.getItem("fallbackLanguage");

          const translationFromStorage =
            getParsedLocalStorageData("translations");
          localStorage.setItem(
            "translations",
            JSON.stringify(languageTranslations)
          );
          localStorage.setItem("defaultLocale", defaultLocale);

          localStorage.setItem("hrefLang", response.hrefLang);

          // if (languageTranslations) {
          //   i18next.addResourceBundle(
          //     defaultLocale,
          //     "translations",
          //     languageTranslations[defaultLocale],
          //     true,
          //     true
          //   );
          // }

          if (
            !translationFromStorage ||
            (JSON.stringify(translationFromStorage) !==
              JSON.stringify(languageTranslations) &&
              response.defaultLocale !== "en_US")
          ) {
            window.location.reload(false);
          }
        }
      })
    );
    yield put(actionCreators.getTranslations(languageTranslations));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
