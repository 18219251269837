import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
export function PasswordInput({
  type = "password",
  label,
  className = "form-control",
  placeholder,
  name,
  errors,
  control,
  ...props
}) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="form-group">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl className={className} variant="outlined" fullWidth>
            <InputLabel shrink={true} htmlFor="outlined-adornment-password">
              {t(label)}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              notched={true}
              placeholder={t(placeholder)}
              variant="outlined"
              label={t(label)}
              fullWidth
              // name={field.name}
              // value={field.value}
              // onChange={field.onChange}

              InputLabelProps={{
                shrink: true,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
              {...field}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  field.onChange(e);
                }
              }}
            />
          </FormControl>
        )}
      />
      {errors[name] && errors[name].message !== "" && (
        <span className="error-message-ag">{errors[name].message}</span>
      )}
    </div>
  );
}
