import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./Store";
import "react-toastify/dist/ReactToastify.css";
import { SettingsUIProvider } from "./SettingUIContext";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./i18next";
import { verifyLocale, fetchDefaultLocale } from "./services/Helpers";

export const MainLayout = () => {
  const [locale] = useState(
    verifyLocale()?.length > 0 && window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1]
      : localStorage.getItem("defaultLocale") ||
          localStorage.getItem("fallbackLanguage")
  );
  const language = locale
    ? locale
    : fetchDefaultLocale()
    ? fetchDefaultLocale()
    : "";

  useEffect(() => {
    if (window.location.pathname === "/" && language) {
      window.location.href = `/${language}`;
    }
  }, [window.location.pathname, language]);

  return (
    <BrowserRouter basename={"/" + language}>
      <CookiesProvider>
        <Provider store={Store}>
          <SettingsUIProvider>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </SettingsUIProvider>
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MainLayout />
  </React.StrictMode>
);
