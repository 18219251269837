import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSettingsUIContext } from "../../SettingUIContext";
export default function HOW_IT_WORKS() {
  const { t, i18n } = useTranslation();
  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      show_how_it_works_section: settingsUIContext.show_how_it_works_section,
    };
  }, [settingsUIContext]);
  return (
    <>
      {settingsUIProps.show_how_it_works_section && (
        <section className="how-it-works">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-12">
                <div className="section-title">
                  <h3>{t("How it Works")}</h3>
                </div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item " role="presentation">
                    <a
                      title={t("Buyer")}
                      className="nav-link"
                      id="buyer-tab"
                      data-toggle="tab"
                      href="#buyer"
                      role="tab"
                      aria-controls="buyer"
                      aria-selected="true"
                    >
                      {t("Buyer")}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      title={t("Seller")}
                      className="nav-link active"
                      id="seller-tab"
                      data-toggle="tab"
                      href="#seller"
                      role="tab"
                      aria-controls="seller"
                      aria-selected="false"
                    >
                      {t("Seller")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 col-sm-8 mx-auto">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="buyer"
                    role="tabpanel"
                    aria-labelledby="buyer-tab"
                  >
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap top">
                          <div className="step">{t("01")}</div>
                          <figure>
                            <img
                              src="/images/discover-any-product.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>
                                {t("Discover")} <br /> {t("Any Product")}
                              </h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap bottom">
                          <div className="step">{t("02")}</div>
                          <figure>
                            <img
                              src="/images/communicate-with-seller.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>
                                {t("Communicate")} <br /> {t("with Seller")}
                              </h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap top">
                          <div className="step">{t("03")}</div>
                          <figure>
                            <img
                              src="/images/buy-selected-product.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>
                                {t("Buy Selected")} <br /> {t("Product")}
                              </h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap">
                          <div className="step">{t("04")}</div>
                          <figure>
                            <img
                              src="/images/spread-the-word.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>
                                {t("Spread")} <br /> {t("the Word")}
                              </h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show active"
                    id="seller"
                    role="tabpanel"
                    aria-labelledby="seller-tab"
                  >
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap bottom">
                          <div className="step">{t("01")}</div>
                          <figure>
                            <img
                              src="/images/001-enter.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>{t("Upload Image")}</h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap top">
                          <div className="step">{t("02")}</div>
                          <figure>
                            <img
                              src="/images/002-picture.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>
                                {t("Post your Ad")}
                                <br />
                              </h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap bottom">
                          <div className="step">{t("03")}</div>
                          <figure>
                            <img
                              src="/images/003-speech-bubble.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>{t("Communicate with Buyer")}</h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="works-wrap">
                          <div className="step">{t("04")}</div>
                          <figure>
                            <img
                              src="/images/004-deal.png"
                              alt=""
                              className="img-fluid"
                            />
                            <figcaption>
                              <h4>{t("Make Deal")}</h4>
                            </figcaption>
                            <div className="clearfix"></div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
