import React, { useEffect, useRef } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Service from "../../services/UserService";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
export default function ChatBox({
  product,
  setMessage,
  createConversation,
  message,
  chatStatus,
  setChatStatus,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let inputRef = useRef();

  const goToPublicProfile = (id) => {
    navigate(`/user-profile/${id}`);
  };

  const goToChat = () => {
    navigate(`/chat`);
  };

  const { user } = useSelector((state) => state);

  useEffect(() => {
    async function getChatStatus() {
      let url = `chat_service/conversation/${product._id}`;
      const response = await Service.Request(url, "", "GET");
      setChatStatus(!response.success);
    }
    if (product && user) {
      getChatStatus();
    }
  }, [product]);

  return (
    <div className="chat-widget">
      <div className="user-profile">
        <figure>
          <img
            src={
              product.user && product.user.image
                ? product.user.image
                : "/images/avatar2.png"
            }
            alt=""
          />
          <figcaption style={{ cursor: "pointer" }}>
            {product && product.user ? (
              <h4
                onClick={(e) => {
                  goToPublicProfile(product.user._id);
                }}
              >
                {product.user.name}{" "}
              </h4>
            ) : null}

            <div className="sales-detail">
              <ul>
                <li>
                  <a>
                    {`${t("Member")} ${t("Since")} `}
                    {product && product.user
                      ? moment(product.user.created_at).format("MMM YYYY")
                      : null}
                  </a>
                </li>
              </ul>
            </div>
          </figcaption>
        </figure>
        <div className="clearfix"></div>
        {chatStatus ? (
          <div className="message-seller">
            <label htmlFor="">{t("Message the seller") + ":"}</label>
            <div className="btn-group">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  createConversation("Still Available?");
                }}
                className="btn btn-secondary"
              >
                {t("Still Available?")}
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  createConversation("Price Negotiable?");
                }}
                className="btn btn-secondary"
              >
                {t("Price Negotiable?")}
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  createConversation("Whats Condition?");
                }}
                className="btn btn-secondary"
              >
                {t("Whats Condition?")}
              </a>
            </div>
            <form className="form-inline my-3 my-lg-0">
              <input
                className="form-control"
                type="input"
                ref={(el) => (inputRef = el)}
                placeholder={t("Chat with seller")}
                aria-label="Search"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter" && message !== "") {
                    ev.preventDefault();
                    createConversation(message);
                  }
                }}
              />
              <button
                title={t("Send")}
                className="btn btn-secondary my-2 my-sm-0"
                type="button"
                onClick={(e) => {
                  if (message != "") {
                    createConversation(message);
                  }
                }}
              >
                {t("Send")}{" "}
                <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
              </button>
            </form>
          </div>
        ) : (
          <button
            title={t("Chat")}
            className="btn btn-secondary my-2 my-sm-0 detailChatBtn"
            type="button"
            onClick={goToChat}
          >
            {t("Chat")}
          </button>
        )}
      </div>
      <div className="clearfix"></div>
    </div>
  );
}
