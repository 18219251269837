import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";

export function* getLanguages() {
  try {
    let languages = yield call(() =>
      Service.Request("setting_service/listactivelanguages", "", "GET").then(
        (response) => {
          let languagesLocale = Array.from(
            response?.data,
            ({ defaultLocale }) => defaultLocale
          );
          let fallbackLng = response?.data?.map((langauge) => {
            if (langauge.languageDefault == 1) {
              localStorage.setItem("fallbackLanguage", langauge.defaultLocale);
            }
          });
          localStorage.setItem(
            "langaugeLocales",
            JSON.stringify(languagesLocale)
          );

          return response.data;
        }
      )
    );
    yield put(actionCreators.getLanguages(languages));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
