import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Service from "../../../services/UserService";
import { toast } from "react-toastify";
import ButtonContent from "./buttonContent";
import moment from "moment";
import { currencyLabel } from "../../../services/Helpers";
import { useSelector } from "react-redux";

export default function App(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const locations = useSelector((state) => state?.locations || {});
  const { settings } = useSelector((state) => ({
    settings: state.settings,
  }));
  let currencySymbol = t(
    currencyLabel(locations?.currencySymbol || settings?.default_currency)
  );
  const { productId, category, subCategory } = location.state;
  const [packageListing, setPackageListing] = useState([]);

  async function getBoughtPackages() {
    if (category && subCategory) {
      const response = await Service.Request(
        `listing_service/user/packages/active?type=Feature_ad&&category=${category}&&subCategory=${subCategory}`,
        {},
        "GET"
      );
      if (response.success && response.data.length > 0) {
        setPackageListing(response.data);
      } else {
        getPackages();
      }
    }
  }

  async function getPackages() {
    if (category && subCategory) {
      const response = await Service.Request(
        `/listing_service/packages?category=${category}&&subCategory=${subCategory}&&type=Feature_ad`,
        {},
        "GET"
      );
      if (response.success) {
        if (response.data.length > 0) {
          setPackageListing(response.data[0]?.packages);
        }
      }
    }
  }

  const buyPackage = async (
    numberOfDays,
    packageId = "",
    boughtPackage = false
  ) => {
    let requestDataObject = {
      category,
      subCategory,
      productId,
      numberOfDays,
      singlePackage: !boughtPackage,
    };
    if (packageId) requestDataObject.packageId = packageId;
    const response = await Service.Request(
      "posting_service/user-package/ad/Feature_ad",
      requestDataObject,
      "POST"
    );
    if (response.success) {
      toast.success(response.message);
      navigate("/profile");
    } else {
      toast.error(response.message);
    }
  };

  const displayPackageListing = () => {
    return packageListing.map((_package) => displayPackageList(_package));
  };
  function displayPackageList(_package) {
    if (!_package.user) {
      return (
        <div className="col-xl-3 col-lg-4 col-md-6 mt-lg-4">
          <div className="featured-pkg-box">
            <h5>{_package.description}</h5>
            <p>{_package?.packageTypeOptions?.packageSelected?.description}</p>
            <h5>
              <strong>
                {currencySymbol} {_package.price}
              </strong>
            </h5>
            <button
              className="btn-primary btn MuiButton-containedSizeLarge"
              onClick={() =>
                buyPackage(
                  _package?.packageTypeOptions?.packageSelected?.numberOfDays
                )
              }
            >
              {t("Feature your ad")}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="col-md-6 col-lg-4 col-xl-3 mt-lg-4">
        <div className="featured-pkg-box">
          <h5>{`Featured ad for ${_package.package.detail.duration} days`}</h5>
          {/* <p>{_package?.packageTypeOptions?.packageSelected?.description}</p> */}
          <p>
            <strong>{t("Used Ads / Total Ads :")}</strong>{" "}
            {_package.package.detail.usedAds} /
            {_package.package.detail.totalAds}
          </p>
          <p className="date">
            <strong>{t("Expiry at:")}</strong>{" "}
            {moment(_package.package.expiredAt).format("dddd, MMMM Do YYYY")}
          </p>
          <button
            className="btn-primary btn MuiButton-containedSizeLarge"
            onClick={() =>
              buyPackage(
                _package?.package?.detail?.duration,
                _package?.package?.detail?._id,
                true
              )
            }
          >
            {t("Use this Package")}
          </button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    getBoughtPackages();
  }, [location]);

  return (
    <React.Fragment>
      <section className="site-mian">
        <div className="container-fluid">
          <div className="post-submitted-content">
            <div className="row w-100">
              <div className="col-lg-12 col-xl-12 mb-lg-0 mb-md-3 mx-auto">
                <div className="post-submitted-content post-submitted-heading mb-md-0 mb-3 d-flex align-items-center">
                  <h3 className="text-success">{t("Congratulations")}!</h3>
                  <p>{t("Your ad has been successfully posted")}</p>
                </div>
                {packageListing && (
                  <div className="post-featured-option mt-5">
                    <div className="row align-items-center">
                      <div className="col-lg-9">
                        <h4>
                          {t(
                            "Reach more buyers and sell faster and upgrade your ad to a top position"
                          )}
                        </h4>
                        <h5>{t("What is featured ad ?")}</h5>
                        <div className="top-selling-tips">
                          <ul>
                            <li>
                              {t(
                                "Get Noticed with featured tag in a top position"
                              )}
                            </li>
                            <li>
                              {t("Ad will be highlighted to top position")}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <div className="col-lg-3">
                        <img
                          className="mw-100"
                          src="/images/featured.jpg"
                        ></img>
                      </div> */}
                    </div>
                    {packageListing.length > 0 && packageListing[0]?.user && (
                      <h4>{t("Your Packages")}</h4>
                    )}
                    <div className="row align-items-center mt-3">
                      {displayPackageListing()}
                    </div>
                    <ButtonContent />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
