import * as actionTypes from "./../actionTypes";
const translations = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSLATIONS:
      return action.translations;
    default:
      return state;
  }
};

export default translations;
