import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import ProductsModule from "./ProductsModule";
import { useSelector } from "react-redux";
import { useSettingsUIContext } from "../../SettingUIContext";
import { useTranslation } from "react-i18next";

export default function FeatureProducts(props) {
  const { t } = useTranslation();
  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      show_featured_products_section:
        settingsUIContext.show_featured_products_section,
    };
  }, [settingsUIContext]);
  const [featureProducts, setFeatureProducts] = useState([]);
  const Products = useSelector((state) => state.featureProducts);
  useEffect(() => {
    setFeatureProducts(Products.featureProducts);
  }, [Products]);
  return (
    <>
      {featureProducts &&
        featureProducts.length > 0 &&
        settingsUIProps.show_featured_products_section && (
          <section className="featured-products px-sm-0">
            <div className="container-fluid px-md-0">
              <div className="row">
                <div className="col-xl-12">
                  <div className="section-title">
                    <h3
                      style={{ display: "inline-block", marginRight: "10px" }}
                    >
                      {t("Featured Products")}
                    </h3>
                    <div className="view-all-brands">
                      <Link
                        title={t("Browse all Products")}
                        to={{
                          pathname: "/category/all",
                        }}
                        state={{
                          prevPath: window.location.pathname,
                        }}
                      >
                        {t("Browse all Products")}{" "}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {featureProducts.length > 0 && (
                        <div className="carousel-item active">
                          <ProductsModule
                            products={featureProducts.slice(0, 4)}
                          />
                        </div>
                      )}

                      {featureProducts.length > 4 && (
                        <div className="carousel-item">
                          <ProductsModule
                            products={featureProducts.slice(4, 8)}
                          />
                        </div>
                      )}
                      {featureProducts.length > 8 && (
                        <div className="carousel-item">
                          <ProductsModule
                            products={featureProducts.slice(8, 12)}
                          />
                        </div>
                      )}
                      {featureProducts.length > 12 && (
                        <div className="carousel-item">
                          <ProductsModule
                            products={featureProducts.slice(12, 16)}
                          />
                        </div>
                      )}
                      {featureProducts.length > 16 && (
                        <div className="carousel-item">
                          <ProductsModule
                            products={featureProducts.slice(16, 20)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {featureProducts.length > 5 && (
                    <>
                      <a
                        title={t("Previous")}
                        className="carousel-control-prev"
                        href="#carouselExampleControls"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        >
                          <i
                            className="fa fa-angle-left"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span className="sr-only">{t("Previous")}</span>
                      </a>
                      <a
                        title={t("Next")}
                        className="carousel-control-next"
                        href="#carouselExampleControls"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        >
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span className="sr-only">{t("Next")}</span>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
    </>
  );
}
