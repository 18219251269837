import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import TopSellers from "../Includes/topsellers";
import How_it_works from "../Includes/how_it_works";
import FeatureProducts from "../Includes/feature-products";
import Banner from "./banner";
import { setFeatureProducts } from "../../actionCreators";
import Loader from "../Product/add/Loader";
import Service from "../../services/UserService";
const ProductCategories = React.lazy(() => import("./productcategories"));

function App() {
  const dispatch = useDispatch();
  function getFeatureProducts() {
    Service.Request(
      "listing_service/products/feature-products?limit=20",
      "",
      "GET"
    ).then((response) => {
      dispatch(setFeatureProducts(response.data));
    });
  }
  useEffect(() => {
    getFeatureProducts();
  }, []);
  return (
    <React.Fragment>
      <Banner />
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader show="true" />
          </div>
        }
      >
        <ProductCategories />
      </Suspense>
      <FeatureProducts />
      <How_it_works />
      <TopSellers />
    </React.Fragment>
  );
}

export default App;
