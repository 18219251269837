import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "./control/InputField";
import { emailPattern } from "../../services/Helpers";

export default function (props) {
  const { ldap, register, reset, errors, control } = props;
  const { t } = useTranslation();
  // useEffect(() => {
  //   reset();
  // }, [ldap]);
  return (
    <>
      <h5>{ldap ? t("LDAP Login") : t("Login Here")}</h5>
      {ldap ? (
        <Input
          {...register("email", { required: t("Username is required") })}
          label={t("Username")}
          name="email"
          placeholder={t("Username")}
          errors={errors}
          control={control}
        />
      ) : (
        <Input
          {...register("email", {
            required: t("Email is required"),
            pattern: {
              value: emailPattern,
              message: t("Invalid email"),
            },
          })}
          label={t("Email")}
          placeholder={t("Email address")}
          // name="email"
          errors={errors}
          control={control}
        />
      )}
    </>
  );
}
