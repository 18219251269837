import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
export default function MetaTitle({ title, direction = "" }) {
  let metaTitle = title;
  const locality = useSelector((state) => state.locality);
  const settings = useSelector((state) => state.settings);

  if (metaTitle) {
    metaTitle = metaTitle.toString().replace("location", locality);
  }
  useEffect(() => {
    if (settings?.favicon) {
      const favicon = document.getElementById("favicon");
      favicon.href = settings?.favicon;
    }
  }, [settings?.favicon]);
  return (
    <Helmet>
      <title>
        {metaTitle &&
          `${metaTitle}  ${
            (settings &&
              settings.website_title &&
              " - " + settings.website_title) ||
            ""
          }`}
      </title>
    </Helmet>
  );
}
