import React from "react";
import Notfound from "./404";

function App() {
  return (
    <React.Fragment>
      <Notfound />
    </React.Fragment>
  );
}

export default App;
