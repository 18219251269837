import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MetaTitle from "../common/MetaTitle";
import Loader from "../Product/add/Loader";

const Notfound = () => {
  const { t } = useTranslation();
  const [locale, setLocale] = useState(localStorage.getItem("defaultLocale"));

  useEffect(() => {
    let defaultLocale = localStorage.getItem("defaultLocale");

    if (defaultLocale) {
      setLocale(defaultLocale);
    }
  }, []);

  return locale ? (
    <>
      <MetaTitle title="Not found" />
      <section className="site-main">
        <div className="container-fluid">
          <div className="error-page">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>{t("You are lost")}</h2>
                <p>
                  {t(
                    "  Sorry the page you are looking for might have been moved, renamed or might never existed"
                  )}
                </p>
                <Link to="/" className="btn btn-primary">
                  {t("Back to Homepage")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <div className="d-flex justify-content-center">
      <Loader show="true" />
    </div>
  );
};

export default Notfound;
