import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ({ showform, setLDAP, ldap, disabled }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="form-row form-group">
        <div className="col">
          <input
            className="styled-checkbox"
            id="styled-checkbox-2"
            type="checkbox"
            value="value2"
          />
          <label htmlFor="styled-checkbox-2">{t("Keep me Login")}</label>
        </div>
        <div className="col">
          <Link
            title={t("Forgot Password?")}
            to="/forget-password"
            className="forgot-password"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Forgot Password?")}
          </Link>
        </div>
      </div>
      <button
        title={t("Login")}
        type="submit"
        className="btn btn-primary loginClass"
      >
        <i class="fa fa-key" aria-hidden="true"></i> {t("Login")}{" "}
        <i className="fa fa-angle-right"></i>
      </button>
      <div className="form-group">
        <button
          type="button"
          className="btn btn-primary loginClass d-none"
          style={{
            padding: "13px",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          disabled={disabled}
          onClick={(e) => setLDAP(!ldap)}
        >
          <i
            class={`fa fa-${ldap ? "envelope" : "desktop"}`}
            aria-hidden="true"
          ></i>
          {t(`Login with ${ldap ? "email" : "LDAP"}`)}
        </button>
      </div>
      {showform && (
        <div className="create-new-account">
          <p>{t("Don’t have an account then")} </p>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              showform();
            }}
          >
            {t("Signup")}
          </a>
        </div>
      )}
    </>
  );
}
