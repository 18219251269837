import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Avatar,
  TextField,
  InputAdornment,
  Button,
  Tabs,
  Tab,
  Paper,
  Box,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import User from "../../services/UserService";
import EmailVerificationModule from "./EmailVerificationModule";
import MobileVerificationModule from "./MobileVerificationModule";
import AddMobile from "./AddMobileModule";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";
import { PasswordInput } from "../common/control/PasswordField";
import { setUser } from "../../actionCreators";
import { emailPattern } from "../../services/Helpers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
function allyProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const SettingsModule = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();
  const { user, settings } = useSelector((state) => state);
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState(0);
  const handleDisabled = () => setDisabled((disabled) => !disabled);

  const handleShowPhone = () => {
    setMobileverification(false);
    setShowMobile(true);
  };
  const password = useRef({});
  password.current = watch("password", "");

  const [isDisabled, setIsDisabled] = useState(true);
  const [name, setName] = useState(
    user.name == user.mobile_number ? "" : user.name
  );
  const [email, setEmail] = useState(
    user.email == user.mobile_number ? "" : user.email
  );
  const [showMobileverification, setMobileverification] = useState(false);
  const [show, setShow] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const InputRef = useRef("fileuploader");

  const handleMobileClose = () => {
    setShowMobile((showMobile) => !showMobile);
  };

  const handleImageClick = (e) => {
    InputRef.current.click();
  };

  const handleImageChange = async (e) => {
    let files = e.target.files;
    let formData = new FormData();
    let imageFile = files[0];
    if (imageFile) {
      if (imageFile.size > 5000000) {
        toast.error(t("The image size should be smaller than 5 MB"));
        return false;
      }
      if (!imageFile.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG)$/)) {
        toast.error(`${imageFile.name} is not a valid image`);
        return false;
      } else {
        formData.append("file", imageFile);
        const response = await User.UploadImage(
          "user_service/upload-image",
          formData,
          "POST",
          ""
        );
        const userUpdatedObject = { ...user, ...response.data };
        localStorage.setItem("user", JSON.stringify(userUpdatedObject));
        dispatch(setUser(userUpdatedObject));
        // toast.success(t(response.message));
      }
    }
  };

  const handleMobileVerification = () => {
    setShowMobile(false);
    setMobileverification((showMobileverification) => !showMobileverification);
  };
  const handleCloseMobile = () => {
    setMobileverification(false);
  };
  const onSubmit = async (data) => {
    setIsDisabled(true);
    const response = await User.Request(
      "user_service/update-user",
      {
        name: name,
        email: email,
      },
      "POST"
    );
    if (response.success) {
      const userUpdatedObject = { ...user, ...response.data };
      await User.asyncLocalStorage.setItem(
        "user",
        JSON.stringify(userUpdatedObject)
      );
      dispatch(setUser(userUpdatedObject));
      if (response.isMail) {
        setShow(true);
      }
      toast.success(t(response.message));
    } else {
      setTimeout(() => {
        setIsDisabled(false);
      }, 1000);

      if (response.allowMail === 1) {
        toast.error(t(response.message));
      } else {
        toast.error(t("Something went wrong"));
      }
    }
  };
  const onSubmitPassword = async (data) => {
    const formData = { new_password: data.password };
    if (data.oldPassword) {
      formData["oldPassword"] = data.oldPassword;
    }
    const response = await User.Request(
      "user_service/update-password",
      {
        ...formData,
      },
      "POST"
    );

    if (response.success) {
      toast.success(t(response.message));
      navigate("/profile");
      setIsDisabled(true);
    } else if (!response.success) {
      toast.error(t(response.message));
    } else {
      toast.error(t("Something went wrong"));
    }
  };

  const handleClose = (e) => setShow((show) => !show);
  useEffect(() => {
    if (
      (user.name !== name || user.email !== email) &&
      name !== "" &&
      name.length >= 3 &&
      /^[a-zA-Z]+( [a-zA-Z]+)+$/.test(name) &&
      email !== "" &&
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, email]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="profile-setting-main">
      <MetaTitle title={t("Settings")} />
      <EmailVerificationModule
        show={show}
        onHide={handleClose}
        email={email}
        close={handleClose}
        submitEmail={onSubmit}
      />
      <AddMobile
        show={showMobile}
        onHide={handleMobileClose}
        close={handleMobileClose}
        showPhoneVerification={handleMobileVerification}
      />
      <MobileVerificationModule
        show={showMobileverification}
        onHide={handleCloseMobile}
        close={handleCloseMobile}
        showPhone={handleShowPhone}
      />

      <Typography className="textHeading">{t("Settings")}</Typography>
      <Grid className="profile-setting-header">
        <div className="left relative">
          <Avatar
            title={t("Upload photo")}
            className="autherSize"
            src={user.image ? user.image : "/images/avatar.jpg"}
            onClick={handleImageClick}
          />
          <span onClick={handleImageClick} className="overState"></span>
          <EditIcon
            onClick={handleImageClick}
            className="editPicture"
            style={{ color: `${settings?.color}` }}
          />
          <input
            type="file"
            accept="image/*"
            ref={InputRef}
            name="profile"
            className="d-none"
            onChange={handleImageChange}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
        </div>
        <div className="right">
          <p className="hypertextMain">
            <span className="hypertext">{t("Tap the photo to change it")}</span>
            {t("Images must be in PNG and JPG format and under 5 MB")}
          </p>
        </div>
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className="settingBtn"
      >
        <LinkTab
          className="tab"
          title={t("Profile")}
          label={t("Profile")}
          href="/profile"
          {...allyProps(0)}
        />
        <LinkTab
          className="tab"
          title={t("Change Password")}
          label={t("Change Password")}
          href="/changePassword"
          {...allyProps(1)}
        />
        <LinkTab
          className="tab"
          title={t("Update Mobile")}
          label={t("Update Mobile")}
          href="/yourdata"
          {...allyProps(1)}
        />
      </Tabs>

      <Paper>
        <TabPanel value={value} index={0}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              className="fieldBalance"
              label={t("Full Name")}
              value={name}
              placeholder={t("Full Name")}
              {...register("name", { required: "Name is required" })}
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^a-zA-Z\s]/, "")
                  .toString()
                  .slice(0, 30);
              }}
              variant="outlined"
              onChange={(e) => {
                setName(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errors.name && errors.name.message !== "" ? (
              <span className="alertBalance">{errors.name.message}</span>
            ) : null}
            <TextField
              className="fieldBalance"
              label={t("Email")}
              value={email}
              placeholder={t("Email")}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: emailPattern,
                  message: t("Invalid email"),
                },
              })}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
              name="email"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errors.email && errors.email.message !== "" ? (
              <span className="alertBalance">{errors.email.message}</span>
            ) : null}
            <TextField
              className="d-none"
              label="Location"
              value="Pakistan"
              fullWidth
              name="location"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              title={t("Save Changes")}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isDisabled}
              className="saveBtnChanges"
            >
              {t("Save Changes")}
            </Button>
          </form>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <p className="no-mobile-yet">
            {user.mobile_number
              ? user.mobile_number
              : t("No mobile number added yet")}
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowMobile(true)}
              className="mobileBtn"
            >
              {user.mobile_number ? t("Update Mobile") : t("Add Mobile")}
            </Button>
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <form onSubmit={handleSubmit(onSubmitPassword)}>
            {user.oldPassword && (
              <PasswordInput
                control={control}
                label={t("Current Password")}
                placeholder={t("Current Password")}
                {...register("oldPassword", {
                  required: t("Current Password is required"),
                })}
                errors={errors}
              />
            )}
            <PasswordInput
              label={t("New password")}
              placeholder={t("New password")}
              control={control}
              {...register("password", {
                required: t("Password is required"),
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: t(
                    "Password must contain uppercase and lowercase letters and at least one number"
                  ),
                },
                minLength: {
                  value: 8,
                  message: t("Password must be 8 characters long"),
                },
              })}
              errors={errors}
            />
            <PasswordInput
              label={t("Confirm password")}
              placeholder={t("Confirm password")}
              control={control}
              {...register("password_repeat", {
                validate: (value) =>
                  value === password.current || t("Passwords do not match"),
              })}
              errors={errors}
            />

            <button
              type="submit"
              className="btn btn-primary changePasswordBtn"
              disabled={disabled}
            >
              {t("Change Password")}
            </button>
          </form>
        </TabPanel>
      </Paper>
    </div>
  );
};

export default SettingsModule;
