import React, { useEffect } from "react";
import ProductList from "./product-list";
import Filters from "./filters";
import Breadcrumbs from "../Includes/breadCrumbs";

function App(props) {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <Filters {...props} />
      <ProductList {...props} />
    </React.Fragment>
  );
}

export default App;
