import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Paper, Tabs, Typography, Box, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import PackagesTable from "./PackagesTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const NavTabs = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="panelProfilemain">
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <LinkTab
            title={t("Active")}
            className="tab"
            label={t("Active")}
            href="/active"
            value={0}
            index={0}
            {...a11yProps(0)}
          />
          <LinkTab
            title={t("Expired / Availed")}
            className="tab"
            label={t("Expired / Availed")}
            href="/expired"
            value={1}
            index={1}
            {...a11yProps(1)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} className="panelProfile">
        <section className="you-will-like featured-products pt-0 profile-products sold-and-selling">
          <div className="container-fluid px-md-2">
            <PackagesTable type="active" />
          </div>
        </section>
      </TabPanel>
      <TabPanel value={value} index={1} className="panelProfile">
        <section className="you-will-like featured-products pt-0 profile-products sold-and-selling">
          <div className="container-fluid px-md-2">
            <PackagesTable type="expire" />
          </div>
        </section>
      </TabPanel>
    </div>
  );
};
export default NavTabs;
