import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

function ButtonContent() {
  const { t } = useTranslation();
  const location = useLocation();
  const { slug, category, subCategory } = location;
  return slug && category ? (
    <div className="text-right mt-5 row">
      <div className="col-md-12">
        <Link
          className="btn btn-primary MuiButton-containedSizeLarge ml-1 mr-1"
          to={slug && `/product/${slug}`}
        >
          {t("View your Ad")}
        </Link>
        <Link
          className="btn btn-primary MuiButton-containedSizeLarge ml-1 mr-1"
          to={
            category && subCategory
              ? `/choose/packages?category=${category}&&subCategory=${subCategory}`
              : "/package/options"
          }
        >
          {t("View more Packages")}
        </Link>
        <Link
          className="btn btn-primary MuiButton-containedSizeLarge ml-1 mr-1"
          to="/profile"
        >
          {t("View Profile")}
        </Link>
      </div>
    </div>
  ) : null;
}

export default ButtonContent;
