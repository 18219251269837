// import { takeEvery, takeLatest, throttle } from "redux-saga";
import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";

export function* getCategories() {
  try {
    let categories = yield call(() =>
      Service.Request("listing_service/categories", "", "GET").then(
        (response) => {
          return response.data;
        }
      )
    );
    yield put(actionCreators.getcategories(categories));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
