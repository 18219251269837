import React, { useState, useEffect } from "react";
import Login from "./email";
import MobileLogin from "./phone_login";
import Verification from "./phone_verification";
import Register from "./register";
import QuickPost from "./QuickPost";
import MobilePasswordLogin from "./MobilePasswordLogin";

const { REACT_APP_LDAP_LOGIN } = process.env;
export default function App(props) {
  const [showRegister, setShowRegister] = useState(false);
  const handleRegister = () => setShowRegister(!showRegister);
  const [showEmailLogin, setEmailLogin] = useState(false);
  const [showQuickPost, setShowQuickPost] = useState(true);
  const [showPhoneLogin, setShowPhoneLogin] = useState(false);
  const [showPhonePassword, setShowPhonePassword] = useState(false);
  const [showPhoneCode, setPhoneCode] = useState(false);
  const [ldap, setLDAP] = useState(REACT_APP_LDAP_LOGIN == "true");

  useEffect(() => {
    setShowPhoneLogin(props.showPhoneLogin);
  }, [props.showPhoneLogin]);

  const handleEmailLogin = () => {
    setShowQuickPost(false);
    setLDAP(false);
    setEmailLogin(!showEmailLogin);
  };
  const handleLDAPLogin = () => {
    setShowQuickPost(false);
    setEmailLogin(!showEmailLogin);
    setLDAP(true);
  };
  const handleLogin = () => {
    setShowPhoneLogin(false);
    setEmailLogin(!showEmailLogin);
    setShowQuickPost(!showQuickPost);
  };

  const handleQuickPost = () => {
    setShowQuickPost(!showQuickPost);
    setShowPhoneLogin(false);
  };
  const handlePhoneLogin = () => {
    setShowQuickPost(false);
    setShowPhonePassword(false);
    setShowPhoneLogin(() => !showPhoneLogin);
  };

  const handlePhonePasswordLogin = () => {
    setShowQuickPost(false);
    setShowPhoneLogin(() => !showPhoneLogin);
    setShowPhonePassword(() => !showPhonePassword);
  };
  const handlePhoneCode = () => {
    setShowPhonePassword(false);
    setShowPhoneLogin(true);
    setPhoneCode(() => !showPhoneCode);
  };

  return (
    <React.Fragment>
      <div className="modal-content login-with-mobile-popup">
        <div className="modal-body" style={{ padding: "0" }}>
          <div
            className="d-flex justify-content-center align-items-center container"
            style={{ padding: "0" }}
          >
            <div className="col-md-12 col-xl-12" style={{ padding: "0" }}>
              <button type="button" className="close" onClick={props.close}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              <div
                className="checkout-info-box"
                style={{ minHeight: "auto", marginBottom: "0" }}
              >
                {!props.loginWithMobile && (
                  <>
                    {showQuickPost && (
                      <QuickPost
                        handleEmailLogin={handleEmailLogin}
                        handlePhoneLogin={handlePhoneLogin}
                        handleLDAPLogin={handleLDAPLogin}
                        {...props}
                      />
                    )}

                    {showRegister && (
                      <Register
                        showform={handleRegister}
                        showLogin={handleLogin}
                      />
                    )}

                    {showEmailLogin && !showRegister && !showQuickPost && (
                      <Login
                        showform={handleRegister}
                        showLogin={handleLogin}
                        ldap={ldap}
                        setLDAP={setLDAP}
                        {...props}
                      />
                    )}
                  </>
                )}

                {showPhoneLogin && showPhoneCode && (
                  <Verification
                    showform={handleRegister}
                    showPhone={handlePhoneCode}
                    {...props}
                  />
                )}

                {!showPhoneLogin && showPhonePassword && (
                  <MobilePasswordLogin
                    showLogin={handlePhoneLogin}
                    showPasswordLogin={handlePhonePasswordLogin}
                    showPhone={handlePhoneCode}
                    {...props}
                  />
                )}

                {showPhoneLogin && !showPhoneCode && (
                  <MobileLogin
                    showform={handleRegister}
                    showPhone={handlePhoneCode}
                    showPasswordLogin={handlePhonePasswordLogin}
                    showLogin={props.loginWithMobile ? null : handleQuickPost}
                    {...props}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
