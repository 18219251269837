import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, FormControl, TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { currencyLabel } from "../../services/Helpers";

const MakeOffer = (props) => {
  const { t } = useTranslation();
  const locations = useSelector((state) => state?.locations || {});
  const { settings } = useSelector((state) => ({
    settings: state.settings,
  }));
  let currencySymbol = t(
    currencyLabel(locations?.currencySymbol || settings?.default_currency)
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const onSubmit = async (data) => {
    const sellerOffer = `Offer Amount: ${currencySymbol} ${data.price} ${data.description}`;
    props.conversation(sellerOffer);
    props.handle();
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      className="make-offer-modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title style={{ lineHeight: "2.5" }}>
          {t("Make an Offer")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="EnterPrice"
              type="number"
              label={t("Offered Amount")}
              style={{ margin: "0 0 20px 0", border: "none" }}
              placeholder={t("Offered Amount")}
              fullWidth
              margin="normal"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/^0+/, "")
                  .toString()
                  .slice(0, 10);
              }}
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              // name="price"
              {...register("price", {
                required: t("Offered amount is required"),
              })}
              {...register("price")}
              {...props}
              error={errors?.price && errors?.price?.message !== ""}
              helperText={errors?.price ? errors?.price?.message : " "}
            />
          </FormControl>

          <div className="form-group">
            <TextField
              id="exampleFormControlTextarea1"
              label={t("Description")}
              multiline
              rows={4}
              placeholder={t("Offer Description")}
              fullWidth
              variant="outlined"
              {...register("description", {
                required: t("Description is required"),
                minLength: {
                  value: 5,
                  message: t("Description must be 5 characters long"),
                },
              })}
              {...props}
              color="primary"
              error={errors?.description && errors?.description?.message !== ""}
              helperText={
                errors?.description ? errors?.description?.message : " "
              }
              InputLabelProps={{
                shrink: true, // Ensure the label floats above the text field
              }}
            />
          </div>

          <FormControl variant="outlined">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className="submitBtn"
            >
              {t("Submit")}
            </Button>
          </FormControl>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default MakeOffer;
