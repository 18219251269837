import { useController, useForm } from "react-hook-form";
import CategoryAttributeInput from "./CategoryAttributeInput";
import React from "react";

export const CategoryAttribute = ({
  catAttrFieldName,
  attribute,
  errors,
  control,
  ...props
}) => {
  //   const {
  //     field: { value, onChange },
  //     fieldState: { error },
  //   } = useController({
  //     name: catAttrFieldName,
  //     control,
  //     defaultValue: "",
  //   });

  return (
    <CategoryAttributeInput
      data={attribute}
      control={control}
      catAttrFieldName={catAttrFieldName}
      //   inputValue={value}
      error={errors["attributes"]?.[attribute._id]?.message || ""}
      helperText={errors["attributes"]?.[attribute._id]?.message}
      {...props}
    />
  );
};
