import loggedInReducer from "./logged";
import category_list from "./getcategories";
import unread_messages from "./unreadMessages";
import featureProducts from "./featureProducts";
import bread_crumbs from "./breadcrumbs";
import search from "./search";
import conversation from "./conversation";
import { combineReducers } from "redux";
import locations from "./locations";
import settings from "./settings";
import languages from "./languages";
import translations from "./translations";
import cmsSettings from "./cmsPages";
import locality from "./locality";
import category from "./category";
import bannerAd from "./bannerAd";
import getUser from "./getUser";
import websiteBanners from "./websiteBanners";
import addOptions from "./addOptions";
import packages from "./packages";
const allReducers = combineReducers({
  loggedIn: loggedInReducer,
  user: getUser,
  categories: category_list,
  breadcrumbs: bread_crumbs,
  featureProducts: featureProducts,
  search: search,
  conversation: conversation,
  unreadMessages: unread_messages,
  locations: locations,
  settings: settings,
  languages: languages,
  translations: translations,
  cmsSettings,
  locality,
  selectedCategory: category,
  bannerAd: bannerAd,
  websiteBanners: websiteBanners,
  addOptions: addOptions,
  packages: packages,
});

export default allReducers;
