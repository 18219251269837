import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useSettingsUIContext } from "../../SettingUIContext";
import { useSelector } from "react-redux";

function Banner() {
  const settingsUIContext = useSettingsUIContext();
  const websiteBanners = useSelector((state) => state.websiteBanners);
  const settingsUIProps = useMemo(() => {
    return {
      show_mobile_app_banner: settingsUIContext.show_mobile_app_banner,
      show_name_suggestion_banner:
        settingsUIContext.show_name_suggestion_banner,
      android_mobile_app_url: settingsUIContext.android_mobile_app_url,
    };
  }, [settingsUIContext]);
  const settings = {
    arrows: true,
    infinite: true,
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };
  return (
    <>
      <section className="site-main">
        <div className="mainBanner">
          <Slider {...settings}>
            {websiteBanners?.length > 0 &&
              websiteBanners.map((websiteBanner, index) => {
                return (
                  <div className="item" key={index}>
                    <Link to="/post-ad">
                      <img
                        src={websiteBanner.image}
                        className="img-fluid"
                        alt=""
                      />
                    </Link>
                  </div>
                );
              })}
          </Slider>
        </div>
      </section>
    </>
  );
}
export default Banner;
