import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoutModule from "./logged-out";
import { useSettingsUIContext } from "../../SettingUIContext";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  const settingsUIContext = useSettingsUIContext();
  const SocialMediaLinks = useSelector((state) => state.settings);
  const settingsUIProps = useMemo(() => {
    return {
      show_social_icons_sidebar: settingsUIContext.show_social_icons_sidebar,
    };
  }, [settingsUIContext]);
  const loggedIn = useSelector((state) => state.loggedIn);
  const [open, setOpen] = useState(false);
  const handleShow = () => setOpen((open) => !open);
  const close = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <LogoutModule open={open} close={close} />
      <div className="profile-wrap">
        <div className="header"></div>
        <input
          type="checkbox"
          className="openSidebarMenu"
          id="openSidebarMenu"
        />
        <div className="openSidebarMenuOverlay"></div>

        <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
          <div className="spinner diagonal part-1"></div>
          <div className="spinner horizontal"></div>
          <div className="spinner diagonal part-2"></div>
        </label>
        <div id="sidebarMenu">
        
          <ul className="sidebarMenuInner">
            <li>
              <Link title={t("Post")} to="/post-ad" className="sale-now">
                <div className="hoverstate">
                  <i></i> <span>{t("Post")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link
                title={loggedIn ? t("My Account") : t("Login")}
                to="/profile/account"
                className="user"
              >
                <div className="hoverstate">
                  {loggedIn ? (
                    <React.Fragment>
                      <i></i>
                      <span>{t("My Account")}</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i></i>
                      <span>{t("Login")}</span>
                    </React.Fragment>
                  )}
                </div>
              </Link>
            </li>
            <li className="d-none">
              <a title={t("Search")} href="#" className="search">
                <div className="hoverstate">
                  <i></i> <span>{t("Search")}</span>
                </div>
              </a>
            </li>
            <li className="d-none">
              <Link title={t("Cart")} to="/cart" className="cart">
                <div className="hoverstate">
                  <i></i> <span>{t("Cart")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link
                title={t("Favorites")}
                to="/profile/favorites"
                className="heart"
              >
                <div className="hoverstate">
                  <React.Fragment>
                    <i></i>
                    <span>{t("Favorites")}</span>
                  </React.Fragment>
                </div>
              </Link>
            </li>
            {loggedIn ? (
              <li>
                {/* <Link to="/logout" className="logout">
                  <div className="hoverstate">
                    <i></i> <span>Logout</span>
                  </div>
                </Link> */}

                <a
                  title={t("Logout")}
                  className="logout"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShow();
                  }}
                >
                  <div className="hoverstate">
                    <i></i> <span>{t("Logout")}</span>
                  </div>
                </a>
              </li>
            ) : null}
          </ul>
          {settingsUIProps.show_social_icons_sidebar && (
            <ul className="social-media">
              {SocialMediaLinks.Facebook && (
                <li>
                  <a
                    title={"Facebook"}
                    href={SocialMediaLinks.Facebook}
                    target="_blank"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
              )}
              {SocialMediaLinks.Twitter && (
                <li>
                  <a
                    title={"Twitter"}
                    href={SocialMediaLinks.Twitter}
                    target="_blank"
                  >
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
              )}
              {SocialMediaLinks.Instagram && (
                <li>
                  <a
                    title={"Instagram"}
                    href={SocialMediaLinks.Instagram}
                    target="_blank"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              )}
              {SocialMediaLinks.Linkedin && (
                <li>
                  <a
                    title={"LinkedIn"}
                    href={SocialMediaLinks.Linkedin}
                    target="_blank"
                  >
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default App;
