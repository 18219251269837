// import { takeEvery, takeLatest, throttle } from "redux-saga";
import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";

export function* getAllSettings() {
  try {
    let settingsData = {};
    let settings = yield call(() =>
      Service.Request("setting_service/default-settings", "", "GET").then(
        (response) => {
          let data = response?.data?.forEach((setting) => {
            settingsData[setting?.name] = setting?.value;
          });

          return settingsData;
        }
      )
    );
    yield put(actionCreators.setSettings(settings));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
