import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Paper, Tabs, Tab } from "@mui/material";
import Service from "../../services/UserService";
import { useParams } from "react-router-dom";
import ConfirmationModule from "./confirmationModule";
import ProfileTabSection from "./ProfileTabSection";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const NavTabs = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state);
  const { favorites } = useParams();
  const [value, setValue] = useState("Selling");
  const [isOpen, setIsOpen] = useState(false);
  const [productId, setProductId] = useState("");
  const [removeProductId, setRemoveProductId] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [productAction, setProductAction] = useState("");
  const handleConformationModel = () => setIsOpen((open) => !open);

  useEffect(() => {
    if (favorites == "favorites") {
      setValue("Favorites");
    }
    if (favorites == "account") {
      setValue("Selling");
    }
  }, [favorites]);

  async function markAsSold(id, index) {
    let url = `posting_service/products/mark-as-sold/${id}`;
    await Service.Request(url, "", "GET");
    setRemoveProductId(id);
  }

  async function markAsActive(id, index) {
    let url = `posting_service/products/mark-as-active/${id}`;
    await Service.Request(url, "", "GET");
    setRemoveProductId(id);
  }

  const deactivateAd = async (id) => {
    await Service.Request(
      `posting_service/products/deactivate/${id}`,
      {},
      "post"
    );
    setRemoveProductId(id);
  };

  const deleteAd = async (id) => {
    await Service.Request(`posting_service/products/${id}`, {}, "delete");
    setRemoveProductId(id);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const markAsSoldModel = (id, index) => {
    setProductId(id);
    setProductAction(t("sold"));
    setModalTitle(t("Mark as sold"));
    setModalText(t("Are you sure you want to mark the post as sold?"));
    setIsOpen(true);
  };

  const markAsActiveModel = (id, index) => {
    setProductId(id);
    setProductAction(t("active"));
    setModalTitle(t("Mark as active"));
    setModalText(t("Are you sure you want to mark the post as active?"));
    setIsOpen(true);
  };

  const deactivateAdModel = (id, index) => {
    setProductId(id);
    setProductAction(t("deactivate"));
    setModalTitle(t("Deactivate Ad"));
    setModalText(t("Are you sure you want to deactivate this ad?"));
    setIsOpen(true);
  };
  const deleteAdModel = (id, index) => {
    setProductId(id);
    setProductAction(t("delete"));
    setModalTitle(t("Delete Ad"));
    setModalText(t("Are you sure you want to delete this ad?"));
    setIsOpen(true);
  };

  return (
    <div className="panelProfilemain">
      <ConfirmationModule
        open={isOpen}
        title={modalTitle}
        text={modalText}
        close={handleConformationModel}
        action={
          productAction === "active"
            ? markAsActive
            : productAction === "sold"
            ? markAsSold
            : productAction === "deactivate"
            ? deactivateAd
            : productAction === "delete"
            ? deleteAd
            : () => void 0
        }
        id={productId}
      />
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <LinkTab
            title={t("Selling")}
            className="tab"
            label={t("Selling")}
            href="/selling"
            value={"Selling"}
          />
          <LinkTab
            title={t("Sold")}
            className="tab"
            label={t("Sold")}
            href="/sold"
            value={"Sold"}
          />
          <LinkTab
            title={t("Pending")}
            className="tab"
            label={t("Pending")}
            href="/pending"
            value={"Pending"}
          />
          <LinkTab
            title={t("Disabled")}
            className="tab"
            label={t("Disabled")}
            href="/disabled"
            value={"Disabled"}
          />
          <LinkTab
            title={t("Favorites")}
            className="tab"
            label={t("Favorites")}
            href="/favorites"
            value={"Favorites"}
          />
        </Tabs>
      </Paper>
      {value && (
        <ProfileTabSection
          value={value}
          sold={markAsSoldModel}
          active={markAsActiveModel}
          deactivate={deactivateAdModel}
          deleteAd={deleteAdModel}
          id={removeProductId}
          setId={setRemoveProductId}
        />
      )}
    </div>
  );
};
export default NavTabs;
