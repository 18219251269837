import React from "react";
import startsWith from "lodash.startswith";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function MobileModal(props) {
  const { submitAction, disableSubmit, error, value, handleChange } = props;
  const { settings, locations } = useSelector((state) => state);

  const { t } = useTranslation();
  return (
    <form onSubmit={submitAction}>
      <div className="form-group">
        <PhoneInput
          isValid={(inputNumber, country, countries) => {
            return countries.some((country) => {
              return (
                startsWith(inputNumber, country.dialCode) ||
                startsWith(country.dialCode, inputNumber)
              );
            });
          }}
          country={
            locations?.phoneLocale?.toLowerCase() || settings?.default_phone
          }
          value={value}
          onChange={handleChange}
          enableSearch={true}
          placeholder={t("Add Phone Number")}
          searchPlaceholder={t("Search")}
          specialLabel=""
          inputStyle={{ width: "100%" }}
          autoFormat={false}
        />
        <span className="error-message-ag">{t(error)}</span>
      </div>
      <button
        title={t("Next")}
        type="submit"
        className="btn btn-primary"
        disabled={disableSubmit}
        style={{ width: "100%" }}
      >
        {t("Next")}
        <i className="fa fa-angle-right"></i>
      </button>
    </form>
  );
}
