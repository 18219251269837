import * as actionTypes from "./../actionTypes";
const locality = (state = "Pakistan", action) => {
  switch (action.type) {
    case actionTypes.LOCALITY:
      return action.locality;
    default:
      return state;
  }
};

export default locality;
