export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const BREADCRUMBS = "BREADCRUMBS";
export const FETCH_CATEGORIES = "fetch_categories";
export const FETCH_UNREADMESSAGES = "fetch_unreadmessages";
export const FETCH_FEATURED = "fetch_featured_products";
export const ERROR = "error";
export const FETCH_COUNTRIES = "fetch_countries";
export const SEARCH = "SEARCH";
export const CONVERSATION = "Conversation";
export const FETCH_LOCATIONS = "fetch_locations";
export const SET_SETTINGS = "SET_SETTINGS";
export const FETCH_LANGUAGES = "fetch_languages";
export const FETCH_TRANSLATIONS = "fetch_translations";
export const SET_CMS_SETTINGS = "SET_CMS_SETTINGS";
export const LOCALITY = "LOCALITY";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";
export const BANNER_ADS = "BANNER_ADS";
export const FEATURE_AND_TOP_PRODUCTS = "FEATURE_AND_TOP_PRODUCTS";
export const SET_USER = "GET_USER";
export const WEBSITE_BANNERS = "WEBSITE_BANNERS";
export const ADD_OPTIONS = "ADD_OPTIONS";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const REMOVE_PACKAGE = "REMOVE_PACKAGE";
export const REMOVE_ALL_PACKAGES = "REMOVE_ALL_PACKAGES";
