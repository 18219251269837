import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Service from "./../../services/UserService";
import { Modal } from "react-bootstrap";
import Login from "./../Models";
import Pagination from "@mui/lab/Pagination";
import "react-magic-slider-dots/dist/magic-dots.css";
import { setBreadCrumbs } from "../../actionCreators";
import Loader from "../Product/add/Loader";
import ProductsModule from "../Includes/ProductsModule";
import {
  prepareFiltersURL,
  getSelectedCategory,
  getAllQuery,
} from "../../services/Helpers";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";

export default function ProductList(props) {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [page, setPage] = useState(1);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(20);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const { category_slug } = useParams();
  const [metaTitle, setMetaTitle] = useState("All Categories");
  let categories = useSelector((state) => state.categories);
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleClose = () => setShowLogin(false);
  useEffect(() => {
    setSearch(location?.search || "");
  }, [location?.search]);

  const getURL = (page, locationData) => {
    let url = "listing_service/products?";
    locationData = locationData?.state;
    let categoryObject;

    if (locationData?.category) {
      categoryObject = locationData.category;
    }

    if (
      (!locationData?.category && locationData?.slug !== "all") ||
      (category_slug !== "all" &&
        category_slug === locationData?.slug &&
        !locationData?.category)
    ) {
      categoryObject = getSelectedCategory(categories, category_slug);
    }
    url += prepareFiltersURL([]);

    if (categoryObject) {
      url += `&category=${categoryObject._id}`;
      setMetaTitle(
        categoryObject.meta_description
          ? categoryObject.meta_description
          : categoryObject.title
      );
    } else {
      setMetaTitle("All Categories");
    }
    url = url.replace("min", "min_price");
    url = url.replace("max", "max_price");
    return url + `&page=${page}`;
  };

  const update_listing = async (location = "") => {
    const response = await Service.Request(getURL(page, location), "", "GET");
    setProducts(response.data);
    setStart(response.start);
    setTotal(response.total_products);
    setEnd(response.end);
    setCount(Math.ceil(response.total_products / response.per_page));
    // dispatch(setSearch({ total: response.total_products }));

    if (response.bread_crumbs) {
      dispatch(setBreadCrumbs(response.bread_crumbs));
    }
  };

  useEffect(() => {
    let _queryParams = getAllQuery(true);
    setPage(+_queryParams["page"] || 1);
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (page && categories?.length > 0) {
      update_listing();
      window.scrollTo(0, 0);
    }
  }, [page, categories]);

  useEffect(() => {
    setStart(1);
    update_listing(location?.pathname);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <React.Fragment>
      <MetaTitle title={metaTitle} />
      <div className="showing-item total-items">
        <p>
          {products?.length !== 0 &&
            `${t("Showing items")} ${start != 1 ? start + 1 : start} ${t(
              "to"
            )} ${end} ${t("of")} ${total}`}
        </p>
      </div>
      <Modal
        show={showLogin}
        onHide={handleClose}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Login close={handleClose} />
      </Modal>
      <section
        className={`you-will-like featured-products pt-0 ${
          total < 5 ? "list-shorter-then-4" : ""
        }`}
      >
        <div className="container-fluid px-0">
          {products && products.length === 0 ? (
            <h4
              className="loader-class"
              style={{ backgroundColor: "#F8F8F8", padding: "25px" }}
            >
              {end == 20 ? <Loader show="true" /> : t("No Product Found")}
            </h4>
          ) : (
            <ProductsModule products={products} />
          )}
        </div>
        {count > 1 ? (
          <div className="product-pagination">
            <Pagination
              count={count}
              page={page}
              onChange={handlePageChange}
              size="large"
              className="productlist-pagination"
            />
          </div>
        ) : null}
      </section>
    </React.Fragment>
  );
}
