import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";
export function* getWebsiteBanners() {
  try {
    const paramLocale = Service.getLocale();
    let websiteBanners = yield call(() =>
      Service.Request(
        `posting_service/websitebanners/list/locale/banners`,
        "",
        "GET"
      ).then(async (response) => {
        return response.data;
      })
    );
    yield put(actionCreators.getWebsiteBanners(websiteBanners));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
