import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

export function Input({
  type = "text",
  label,
  className = "form-control",
  placeholder,
  name = "",
  inputRef = "",
  errors,
  control,
  onChange = function (e) {},
  ...props
}) {
  const { t } = useTranslation();
  let newLabel;

  if (typeof label === "string" && label.includes("*")) {
    console.log("🚀 ~ label:", label);
    newLabel = (
      <span>
        {label.replace("*", "").trim()} <span className="text-danger">*</span>
      </span>
    );
  } else {
    newLabel = label;
  }

  return (
    <div className="form-group">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <TextField
              type={type}
              label={newLabel}
              variant="outlined"
              fullWidth
              // autoComplete="off"
              className={className}
              placeholder={t(placeholder)}
              InputLabelProps={{ shrink: true }}
              // inputRef={inputRef}
              {...props}
              {...field}
              onChange={(e) => {
                const { value } = e.target;
                const { inputType } = e.nativeEvent;
                if (inputType === "deleteContentBackward" || value.trim()) {
                  onChange(e);
                  field.onChange(e);
                }
              }}
            />
          );
        }}
      />

      {errors?.[name] && errors?.[name]?.message !== "" && (
        <span className="error-message-ag">{errors[name].message}</span>
      )}
    </div>
  );
}
