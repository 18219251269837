// import { takeEvery, takeLatest, throttle } from "redux-saga";
import { call, put, delay } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";

export function* getLocations() {
  try {
    yield delay(2000);
    let locations = yield call(() =>
      Service.Request("listing_service/locations", "", "GET").then(
        (response) => {
          return response.data;
        }
      )
    );
    yield put(actionCreators.getLocations(locations));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
