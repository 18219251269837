import * as actionTypes from "./../actionTypes";
import { getParsedLocalStorageData } from "../services/Helpers";
const user = getParsedLocalStorageData("user");
const getUser = (state = user || {}, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return action.user;
    default:
      return state;
  }
};

export default getUser;
