import React from "react";
import EditProduct from "./edit_product";
import { useParams } from "react-router-dom";

function App(props) {
  const { slug } = useParams();
  return (
    <React.Fragment>
      <EditProduct slug={slug} {...props} />
    </React.Fragment>
  );
}

export default App;
