import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function Loader(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.show ? (
        <React.Fragment>
          <CircularProgress
            variant="determinate"
            className={classes.bottom}
            size={40}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.top + " primaryclr"}
            classes={{
              circle: classes.circle,
            }}
            size={40}
            thickness={4}
            {...props}
          />
        </React.Fragment>
      ) : null}
    </div>
  );
}
export default Loader;
