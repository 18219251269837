import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginAction, setUser } from "../../actionCreators";
import MobileLogin from "../Models";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";
import { Modal } from "react-bootstrap";
import SocialLogin from "../common/SocialLogin";
import { PasswordInput } from "../common/control/PasswordField";
import LoginPageContent from "../common/content/LoginPageContent";
import LdapLogin from "../common/LdapLogin";
import * as yup from "yup";

const { REACT_APP_LDAP_LOGIN } = process.env;

export default function App(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [ldap, setLDAP] = useState(REACT_APP_LDAP_LOGIN === "true");

  const closeMobileModal = () => setShowMobileModal(false);

  const handleDisabled = () => setDisabled((prevDisabled) => !prevDisabled);

  const useYupValidationResolver = (validationSchema) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const validationSchema = yup.object({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues, resolver });

  const onSubmit = async (data) => {
    handleDisabled();

    let response;
    const loginData = {
      email: data.email,
      password: data.password,
    };

    if (ldap) {
      response = await Auth.doUserRequest("user_service/ldaplogin", loginData);
    } else {
      response = await Auth.doUserRequest("user_service/login", loginData);
    }

    if (response.success) {
      window.location.reload(false);
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.token));

      dispatch(loginAction());
      dispatch(setUser(response.data));

      navigate("/");
    } else {
      setTimeout(handleDisabled, 2000);
      toast.error(t(response.message) || "Network error");
    }
  };

  return (
    <>
      <MetaTitle title="Login" />
      <Modal
        show={showMobileModal}
        onHide={closeMobileModal}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <MobileLogin
          loginWithMobile
          showPhoneLogin={showMobileModal}
          close={closeMobileModal}
        />
      </Modal>

      <div className="d-flex justify-content-center align-items-center container login-auth-page">
        <div className="col-md-7 col-xl-5">
          <div className="checkout-info-box" style={{ minHeight: "auto" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LdapLogin
                ldap={ldap}
                control={control}
                reset={reset}
                register={register}
                errors={errors}
                {...props}
              />

              <PasswordInput
                label={t("Password")}
                placeholder={t("Password")}
                errors={errors}
                {...register("password", {
                  required: t("Password is required"),
                })}
                control={control}
                {...props}
              />

              <LoginPageContent
                disabled={disabled}
                ldap={ldap}
                setLDAP={setLDAP}
                {...props}
              />

              <button
                title={t("Login with Mobile")}
                type="button"
                className="btn btn-primary loginClass"
                style={{
                  padding: "13px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
                disabled={disabled}
                onClick={() => setShowMobileModal(true)}
              >
                <i className="fa fa-mobile" aria-hidden="true"></i>
                {t("Login with Mobile")}
              </button>

              <SocialLogin disabled={disabled} />

              <div className="create-new-account">
                <p>{t("Don’t have an account?")} </p>
                <Link title={t("Signup")} to="/register">
                  {t("Signup")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
