import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ProductSuccess from "./productSuccess";
import ProductError from "./productError";

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug, productId, category, subCategory, success } = location.state;
  useEffect(() => {
    if (!slug || !productId || !category || !subCategory) {
      navigate("/profile");
    }
  }, []);
  return (
    <React.Fragment>
      {success ? <ProductSuccess {...props} /> : <ProductError {...props} />}
    </React.Fragment>
  );
}

export default App;
