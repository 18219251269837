import * as actionTypes from "../actionTypes";
const addOptions = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_OPTIONS:
      return action.addOptions;
    default:
      return state;
  }
};

export default addOptions;
