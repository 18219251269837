import * as actionTypes from "./../actionTypes";
const conversation = (state = {}, action) => {
  switch (action.type) {
    // case actionTypes.GET_USERS:
    //   return Object.assign({}, prevState, {isFetching: true});
    case actionTypes.CONVERSATION:
      return action.conversation;
    default:
      return state;
  }
};

export default conversation;
