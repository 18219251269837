let isLoggedValue =
  localStorage.getItem("user") && localStorage.getItem("token") ? true : false;

const loggedInReducer = (state = isLoggedValue, action) => {
  switch (action.type) {
    case "LOGIN":
      return true;
    case "LOGOUT":
      return false;
    default:
      return state;
  }
};

export default loggedInReducer;
