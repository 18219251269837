import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addPackage, removePackage } from "../../actionCreators/index";
import { toast } from "react-toastify";
import Service from "../../services/UserService";
import MetaTitle from "../common/MetaTitle";
import { currencyLabel } from "../../services/Helpers";

const ViewCart = () => {
  const { t } = useTranslation();
  const selectedPackages = useSelector((state) => state.packages, shallowEqual);
  const locations = useSelector(
    (state) => state?.locations || {},
    shallowEqual
  );
  const { settings } = useSelector((state) => ({
    settings: state.settings,
    shallowEqual,
  }));
  let currencySymbol = t(
    currencyLabel(locations?.currencySymbol || settings?.default_currency)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  let formatter = new Intl.NumberFormat("en-US", {});

  useEffect(() => {
    let packagesFromLocalStorage = localStorage.getItem("packages");
    if (packagesFromLocalStorage)
      packagesFromLocalStorage = JSON.parse(packagesFromLocalStorage);
    if (
      (packagesFromLocalStorage &&
        packagesFromLocalStorage.length > 0 &&
        packagesFromLocalStorage.length === selectedPackages.length + 1) ||
      selectedPackages.length > 0
    ) {
      localStorage.setItem("packages", JSON.stringify(selectedPackages));
    }
    setPackages(selectedPackages);
  }, [selectedPackages]);

  useEffect(() => {
    const packagesFromLocalStorage = JSON.parse(
      localStorage.getItem("packages")
    );
    if (
      packagesFromLocalStorage &&
      packagesFromLocalStorage.length !== selectedPackages.length
    ) {
      setPackages(packagesFromLocalStorage);

      dispatch(addPackage(packagesFromLocalStorage));
    }
  }, [dispatch]);

  useEffect(() => {
    // if (packages) {
    //   localStorage.setItem("packages", JSON.stringify(packages));
    // }
  }, [packages]);

  useEffect(() => {
    if (packages.length > 0) {
      setPrice(
        packages.reduce(function (previousValue, currentValue) {
          return (
            previousValue + +currentValue.actualPrice * +currentValue.quantity
          );
        }, 0)
      );
      setTotalPrice(
        packages.reduce(function (previousValue, currentValue) {
          return previousValue + +currentValue.price * +currentValue.quantity;
        }, 0)
      );
    } else {
      setDiscountPrice(0);
      setTotalPrice(0);
      setPrice(0);
    }
  }, [packages]);

  useEffect(() => {
    if (price > 0 && totalPrice > 0) {
      setDiscountPrice(price - totalPrice);
    }
  }, [price, totalPrice]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const increment = (id) => {
    setPackages(
      packages.filter((p) => {
        if (p.id === id) {
          p.quantity = p.quantity + 1;
        }
        return p;
      })
    );
  };
  const decrement = (id) => {
    setPackages(
      packages.filter((p) => {
        if (p.id === id) {
          p.quantity = p.quantity - 1;
        }
        return p;
      })
    );
  };

  const payAmount = async () => {
    const userSelectedPackages = packages.map((_package) => {
      return { package: _package._id, quantity: _package.quantity };
    });
    const response = await Service.Request(
      "/posting_service/user-package",
      { userPackages: userSelectedPackages },
      "POST"
    );
    if (response.success) {
      toast.success(response.message);
      localStorage.removeItem("packages");
      dispatch(addPackage([]));
      navigate("/");
    } else {
      toast.error(response.message || "Network error");
    }
  };

  return (
    <React.Fragment>
      <MetaTitle title="View Cart" />
      <section className="view-cart-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="packages-options-main">
                <div className="pack-heading px-0">
                  <div className="section-title">
                    <a className="back" href="#">
                      <i className="fa fa-arrow-left"></i>
                    </a>
                    <h5 className="mb-0">{t("View Cart")}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="view-cart-left">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="title" id="title">
                        {t("Title")}
                      </th>
                      <th className="price" id="price">
                        {t("Price")}
                      </th>
                      <th className="quantity" id="quantity">
                        {t("QTY")}
                      </th>
                      <th className="remove" id="remove"></th>
                      <th className="subtotal" id="subtotal">
                        {t("Subtotal")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packages.map((_package, index) => (
                      <tr key={index}>
                        <td className="title" data-title="title">
                          <div>
                            <span>{_package.name}</span>
                            <span>
                              {
                                _package?.packageTypeOptions?.packageSelected
                                  ?.heading
                              }
                            </span>
                            <span>
                              {
                                _package?.packageTypeOptions?.packageSelected
                                  ?.description
                              }
                            </span>
                          </div>
                        </td>
                        <td className="price" data-title="price">
                          <div>
                            <span className="discounted">
                              <span>{currencySymbol} </span>
                              {formatter.format(_package.price)}
                            </span>
                            <del className="old">
                              <span>{currencySymbol} </span>
                              {formatter.format(_package.actualPrice)}
                            </del>
                          </div>
                        </td>
                        <td className="quantity" data-title="quantity">
                          <div className="qty">
                            <div className="field">
                              <div className="inner-field">
                                <button
                                  type="button"
                                  className="sub"
                                  disabled={_package.quantity < 2}
                                  onClick={() => {
                                    if (_package.quantity > 1) {
                                      decrement(_package._id);
                                    }
                                  }}
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  value={_package.quantity}
                                  min="1"
                                  max="3"
                                />
                                <button
                                  type="button"
                                  className="add"
                                  disabled={_package.quantity > 4}
                                  onClick={() => {
                                    if (_package.quantity < 5) {
                                      increment(_package._id);
                                    }
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="remove" data-title="remove">
                          <span
                            onClick={(e) => {
                              dispatch(removePackage(_package));
                            }}
                          >
                            {t("remove")}
                          </span>
                        </td>
                        <td className="subtotal" data-title="subtotal">
                          <div>
                            <span>
                              <span>{currencySymbol} </span>
                              {formatter.format(
                                _package.price * _package.quantity || 1
                              )}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {packages.length === 0 && (
                <h6
                  className="d-flex justify-content-center"
                  style={{
                    backgroundColor: "rgb(248, 248, 248)",
                    padding: "25px",
                  }}
                >
                  {t("Cart is Empty")}
                </h6>
              )}
            </div>
            <div className="col-lg-4">
              <div className="view-cart-right">
                <div className="total-price-main">
                  <div className="heading">
                    <h6>{t("PRICE DETAILS")}</h6>
                  </div>

                  <div className="content-box">
                    <div className="price-detail">
                      <div>
                        <span>{t("Price")}</span>
                        <span>
                          {currencySymbol} {formatter.format(price)}
                        </span>
                      </div>
                      <div>
                        <span>{t("Discount")}</span>
                        <span>
                          {currencySymbol}
                          {formatter.format(discountPrice)}
                        </span>
                      </div>
                      <div className="bottom">
                        <span>{t("total")}</span>
                        <span>
                          <span>{currencySymbol} </span>{" "}
                          {formatter.format(totalPrice)}
                        </span>
                      </div>
                    </div>
                    <div className="btn pb-0">
                      <button
                        type="button"
                        disabled={packages.length === 0 ? true : false}
                        onClick={payAmount}
                      >
                        {t("Pay")} {currencySymbol}{" "}
                        {formatter.format(totalPrice)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ViewCart;
