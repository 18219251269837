import React, { createContext, useContext, useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";

const SettingsUIContext = createContext();

export function useSettingsUIContext() {
  return useContext(SettingsUIContext);
}

export const SettingsUIConsumer = SettingsUIContext.Consumer;

export function SettingsUIProvider({ children }) {
  const settings = useSelector((state) => state.settings, shallowEqual);

  return (
    <SettingsUIContext.Provider value={settings}>
      {children}
    </SettingsUIContext.Provider>
  );
}
