import React from "react";
import { useTranslation } from "react-i18next";

const ProductTips = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="col-lg-4 col-xl-4">
        <div className="top-selling-tips">
          <h4>
            <i className="fas fa-lightbulb"></i>{" "}
            {t("Top Tips for Selling at Speed")}
          </h4>
          <ul>
            <li>
              {t("Step 1")}: <strong>{t("Upload and Post")}.</strong>
              <p>{t("Login with your account")}.</p>
              <p>{t("Post 2-3 clear photos of your product(s)")} </p>
              <p>{t("The ideal photo resolution is 1000x800 pixels")}</p>
            </li>
            <li>
              {t("Step 2")}:{" "}
              <strong>{t("Write down a Detailed Description")}. </strong>
              <p>
                {t(
                  "Write a detailed description of your product. Share the details thoroughly"
                )}
                .
              </p>
              <p>
                {t("Mention all the features and properties of the product")}.
              </p>
            </li>
            <li>
              {t("Step 3")}: <strong>{t("Set a Price")}.</strong>{" "}
              <p>{t("Set a appropriate price for your product")}.</p>
              <p>
                {t(
                  "We encourage setting a realistic price, so the product sells quickly"
                )}
                .
              </p>
            </li>
            <li>
              {t("Step 4")}: <strong>{t("Post Your Product")}</strong>
              <p>
                {t(
                  "Once you have followed the above steps,click on the post button"
                )}
                .
              </p>
              <p>
                {t(
                  "There you go; your product is now reaching a large audience"
                )}
                .{" "}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductTips;
