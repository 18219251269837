import * as actionTypes from "./../actionTypes";
const bread_crumbs = (state = [], action) => {
  switch (action.type) {
    // case actionTypes.GET_USERS:
    //   return Object.assign({}, prevState, {isFetching: true});
    case actionTypes.BREADCRUMBS:
      return action.breadcrumbs;
    default:
      return state;
  }
};

export default bread_crumbs;
