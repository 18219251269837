import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import EmailVerificationModule from "./EmailVerificationModule";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";
import { Input } from "../common/control/InputField";
import { PasswordInput } from "../common/control/PasswordField";
import { emailPattern } from "../../services/Helpers";

export default function App(props) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm();
  const navigate = useNavigate();
  let email;
  const [formData, setFormData] = useState();
  const [disabled, setDisabled] = useState(false);
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal((showModal) => !showModal);
  const password = useRef({});
  password.current = watch("password", "");

  const handleDisabledButton = () => setDisabled((disabled) => !disabled);

  const onSubmit = async (data) => {
    handleDisabledButton();
    localStorage.setItem("email", data.email);
    email = data.email;
    setFormData(data);
    const response = await Auth.doUserRequest("user_service/register", {
      name: data.name,
      email: data.email,
      password: data.password,
    });
    if (response.success) {
      toast.success(t("Verification code sent successfully"));
      setShowModal(true);
      // handleModal();
      // history.push("/login");
    } else {
      toast.error(t(response.message) || t("Network error"));
    }
    setTimeout(() => {
      handleDisabledButton();
    }, 2000);
  };

  return (
    <React.Fragment>
      <MetaTitle title="Register" />
      <EmailVerificationModule
        show={showModal}
        onHide={handleModal}
        email={email}
        close={handleModal}
        submit={onSubmit}
        data={formData}
      />
      <div className="d-flex justify-content-center align-items-center container register-imac-padding">
        <div className="col-md-12 col-xl-5">
          <div className="checkout-info-box" style={{ minHeight: "auto" }}>
            <h5>{t("Register Here")}</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group register-btn-label-align">
                <Input className="input-name"
                  {...register("name", {
                    required: t("Name is required"),
                    minLength: {
                      value: 3,
                      message: t("Name must be 3 characters long"),
                    },
                    maxLength: 30,
                  })}
                  label={t("Full Name *")}
                  placeholder={t("Full Name")}
                  name="name"
                  errors={errors}
                  control={control}
                />
              </div>
              <div className="form-group register-btn-label-align">
                <Input className="input-name"
                  {...register("email", {
                    required: t("Email is required"),
                    pattern: {
                      value: emailPattern,
                      message: t("Invalid email"),
                    },
                  })}
                  label={t("Email *")}
                  placeholder={t("Email address")}
                  name="email"
                  errors={errors}
                  control={control}
                />
              </div>
              <div className="form-group register-btn-label-align">
                <PasswordInput className="input-name"
                  label={t("Password *")}
                  placeholder={t("Password")}
                  errors={errors}
                  {...register("password", {
                    required: t("Password is required"),
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                      message: t(
                        "Password must contain uppercase and lowercase letters and at least one number"
                      ),
                    },
                    minLength: {
                      value: 8,
                      message: t("Password must be 8 characters long"),
                    },
                  })}
                  control={control}
                  {...props}
                />
              </div>
              <div className="form-group register-btn-label-align">
                <PasswordInput className="input-name"
                  label={t("Confirm password *")}
                  placeholder={t("Confirm password")}
                  errors={errors}
                  {...register("password_repeat", {
                    validate: (value) =>
                      value === password.current || t("Passwords do not match"),
                  })}
                  control={control}
                  {...props}
                />
              </div>

              <div className="form-row form-group"></div>
              <button
                title={t("Register")}
                type="submit"
                className="btn btn-primary"
                disabled={disabled}
              >
                {t("Register")} <i className="fa fa-angle-right"></i>
              </button>
              <div className="create-new-account">
                <p>{t("Already have an account then")} </p>
                <Link title={t("Login Here")} to="/login">
                  {" "}
                  <i className="fa fa-key" aria-hidden="true"></i>{" "}
                  {t("Login Here")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
