import * as actionTypes from "./../actionTypes";
export const loginAction = () => {
  return {
    type: actionTypes.LOGIN,
  };
};

export const logoutAction = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

export const setBreadCrumbs = (data) => {
  return {
    type: actionTypes.BREADCRUMBS,
    breadcrumbs: data,
  };
};
export const setUser = (data) => {
  return {
    type: actionTypes.SET_USER,
    user: data,
  };
};

export const setSearch = (data) => {
  return {
    type: actionTypes.SEARCH,
    search: data,
  };
};

export function getcategories(categories) {
  return {
    type: actionTypes.FETCH_CATEGORIES,
    categories,
  };
}

export function getUnreadMessages(unreadMessages) {
  return {
    type: actionTypes.FETCH_UNREADMESSAGES,
    unreadMessages,
  };
}

export function getFeatureProducts(featureProducts) {
  return {
    type: actionTypes.FETCH_FEATURED,
    featureProducts,
  };
}

export function setFeatureProducts(featureProducts) {
  return {
    type: actionTypes.FETCH_FEATURED,
    featureProducts,
  };
}
export function setConversation(conversation) {
  return {
    type: actionTypes.CONVERSATION,
    conversation,
  };
}
export function getConversation(conversation) {
  return {
    type: actionTypes.CONVERSATION,
    conversation,
  };
}

export function getLocations(locations) {
  return {
    type: actionTypes.FETCH_LOCATIONS,
    locations,
  };
}
export const getBannerAds = (bannerAds) => {
  return {
    type: actionTypes.BANNER_ADS,
    bannerAds,
  };
};
export function setSettings(settings) {
  return {
    type: actionTypes.SET_SETTINGS,
    settings,
  };
}

export function setCmsPages(cmsData) {
  return {
    type: actionTypes.SET_CMS_SETTINGS,
    cmsData,
  };
}

export const error = (error) => {
  return {
    type: actionTypes.ERROR,
    error,
  };
};

export const getLanguages = (languages) => {
  return {
    type: actionTypes.FETCH_LANGUAGES,
    languages,
  };
};
export const getTranslations = (translations) => {
  return {
    type: actionTypes.FETCH_TRANSLATIONS,
    translations,
  };
};
export const setLocality = (locality) => {
  return {
    type: actionTypes.LOCALITY,
    locality,
  };
};

export const setSelectedCategory = (category) => {
  return {
    type: actionTypes.SELECTED_CATEGORY,
    category,
  };
};
export const getWebsiteBanners = (websiteBanners) => {
  return {
    type: actionTypes.WEBSITE_BANNERS,
    websiteBanners,
  };
};
export function setAddOptions(addOptions) {
  return {
    type: actionTypes.ADD_OPTIONS,
    addOptions,
  };
}

export function addPackage(_package) {
  return {
    type: actionTypes.ADD_PACKAGE,
    package: _package,
  };
}
export function removePackage(_package) {
  return {
    type: actionTypes.REMOVE_PACKAGE,
    package: _package,
  };
}
export function removeAllPackages() {
  return {
    type: actionTypes.REMOVE_ALL_PACKAGES,
    packages: [],
  };
}
