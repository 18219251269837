import React from "react";
import Register from "./register";

function App() {
  return (
    <React.Fragment>
      <Register />
    </React.Fragment>
  );
}

export default App;
