import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Service from "../../services/UserService";
import moment from "moment";
import Login from "../Models";
import Breadcrumb from "../Includes/breadCrumbs";
import ShareModel from "../Profile/ShareModule";
import MakeOfferModel from "../Models/make_offer";
import ReportProduct from "./reportProduct";
import Map from "../Includes/map";
import { socket } from "../../services/Socket";
import ProductsTabs from "./ProductsTabs";
import ChatBox from "./ChatBox";
import { useTranslation } from "react-i18next";
import MetaTitle from "../common/MetaTitle";
import Loader from "../Product/add/Loader";
import { BannerAd } from "../BannerAd/BannerAd";
import ImagesSlider from "./ImagesSlider";
import {
  getParsedLocalStorageData,
  standardPricing,
  urduPricing,
} from "../../services/Helpers";
import NotFound from "../404";
import { Height } from "@mui/icons-material";

export default function App(props) {
  const token = getParsedLocalStorageData("token");
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [socketState, setSocketState] = useState(socket);
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [like, setLike] = useState(false);
  const [product, setProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [topProducts, setTopProducts] = useState(
    useSelector((state) => state.featureProducts)
  );
  const { bannerAds, settings, locations } = useSelector((state) => ({
    bannerAds: state.bannerAd,
    settings: state.settings,
    locations: state.locations || {},
  }));
  const scrollTop = () => {
    console.log("initiated");
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const { user } = useSelector((state) => state);
  const [userProducts, setUserProducts] = useState([]);
  const [showReportModel, setShowReportModel] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [chatStatus, setChatStatus] = useState(true);

  const handleClose = () => setShowLogin(false);
  const handleReportShow = () => setShowReportModel(!showReportModel);
  useEffect(() => {
    async function connectSocket() {
      const { connect } = await import("../../services/Socket");
      setSocketState(connect(user));
    }
    if (!socketState && user && user?._id) {
      connectSocket();
    }
  });

  const createConversation = async (content) => {
    if (product && token) {
      let conversationObject = {
        product: {
          _id: product._id,
          title: product.title,
          price: product.price,
          slug: product.slug,
          image: product.images[0].replace("large", "small"),
        },
        seller: {
          _id: product.user._id,
          name: product.user.name,
          imagePath: product.user.imagePath
            ? product.user.imagePath
            : "avatar.png",
        },
        content,
      };
      const response = await Service.Request(
        `chat_service/conversation`,
        conversationObject,
        "POST"
      );
      if (response.success) {
        socketState.emit("conversationCreated", {
          id: user._id,
          userId: product.user._id,
          content,
        });

        toast.success(t(response.message));
        setChatStatus(false);
      } else {
        toast.error(t(response.message));
      }
    } else {
      setShowLogin(true);
    }
  };

  const handleOffer = (_show) => {
    setShowOffer(_show);
  };

  //Handling Make an Offer Model
  const handleMakeOffer = () => {
    if (product && token) {
      setShowOffer(true);
    } else {
      setShowLogin(true);
    }
  };

  //Handling report Model

  const handleReportItemModel = () => {
    if (product && token) {
      setShowReportModel(true);
    } else {
      setShowLogin(true);
    }
  };
  async function getProductDetail(url) {
    if (props.slug) {
      url = url + props.slug;
      const response = await Service.Request(url, "", "GET");
      if (response.success && response?.data?.length !== 0) {
        if (
          response.data.product.status.toString() !== "1" &&
          response.data.product.user._id !== user._id
        ) {
          setProduct("NOTFOUND");
        } else {
          setLike(response.data.product.is_favorite);
          setProduct(response.data.product);
          await Service.Request(
            `posting_service/products/view/${response.data.product._id}`,
            "",
            "GET"
          );
          if (response.data.bread_crumbs) {
            setBreadCrumb(response.data.bread_crumbs);
          }
        }
      } else {
        setProduct("NOTFOUND");
      }
    } else {
      setProduct("NOTFOUND");
    }
  }

  //Get Similar Products
  async function get() {
    if (product && product.category) {
      let url = "listing_service/products?category=" + product.category._id;
      const related_products = await Service.Request(url, "", "GET");
      setSimilarProducts(
        related_products.data.filter((p) => p._id !== product._id)
      );
    }
  }

  //LIKE / DISLIKE AD
  async function AddRemoveFav(like) {
    if (!token) setShowLogin(true);
    if (product?._id && token) {
      let url = like
        ? `posting_service/favorites/${product._id}`
        : `posting_service/favorites/${product._id}`;

      await Service.Request(url, {}, like ? "DELETE" : "GET");
      setLike(!like);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    let url = "listing_service/products/detail/";
    getProductDetail(url);
  }, [props.slug]);

  useEffect(() => {
    get();
  }, [product, props.slug]);

  useEffect(() => {
    async function get() {
      let url = "listing_service/products/feature-products";
      const top_products = await Service.Request(url, "", "GET");
      setTopProducts(
        top_products.data.topProducts.filter((p) => p._id !== product._id)
      );
    }
    if (product && product.user) {
      get();
    }
  }, [product]);

  useEffect(() => {
    async function get() {
      let url = "listing_service/products/selling";
      const user_products = await Service.Request(
        url,
        {
          user_id: product && product.user ? product.user._id : "",
        },
        "POST"
      );
      let data = user_products.success ? user_products.data : [];
      setUserProducts(data);
    }
    if (product && product.user) {
      get();
    }
  }, [product]);

  const _attributeItem = (attr) => {
    const { title, value, unit, type } = attr;
    return (
      <div className="col-lg-6 col-md-6 col-sm-6 px-0 mt-2">
        <div className="row mx-auto">
          <div className="col-lg-6 col-md-6 col-6 text-muted">{t(title)}</div>
          <div className="col-lg-6 col-md-6 col-6">
            {type && type === "options" ? t(value) + " " : value + " "}
            {type && type === "options" ? t(unit) : unit}
          </div>
        </div>
      </div>
    );
  };

  const _attributes = () => {
    if (!product || Object.keys(product).length === 0) return null;

    if (!product.attributes) return null;

    if (product.attributes.length == 0) return null;

    return (
      <div className="product-attributes">
        <div className="row">
          {product.attributes.map(_attributeItem)}
          <div className="clearfix"></div>
        </div>
        <hr />
      </div>
    );
  };

  const productStatus = (status) => {
    switch (status) {
      case 0:
        return "This ad has been inactive";
      case 2:
        return "This ad has been sold";
      case 3:
        return "This ad is pending";
      case 4:
        return "This ad has been expired";
      default:
        return;
    }
  };

  const locale = localStorage.getItem("defaultLocale");
  return typeof product === "object" && Object.keys(product).length > 0 ? (
    <section className="product-detail v2">
      <Modal
        show={showLogin}
        onHide={handleClose}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="fullscreen"
      >
        <Login close={handleClose} />
      </Modal>

      <MakeOfferModel
        handle={handleOffer}
        onHide={handleOffer}
        show={showOffer}
        conversation={createConversation}
      />

      <ReportProduct
        handle={handleReportShow}
        onHide={handleReportShow}
        show={showReportModel}
        product_id={product._id}
      />

      <div
        className={`container-fluid realated-products ${
          product && product.images && product.images.length === 1
            ? "single-slider"
            : ""
        }`}
      >
        <MetaTitle
          title={
            breadCrumb.length > 3
              ? typeof product.title !== "undefined" &&
                typeof breadCrumb[2]?.title !== "undefined" &&
                product?.title + " - " + breadCrumb[2].title
              : typeof product.title !== "undefined" &&
                typeof breadCrumb[1]?.title !== "undefined" &&
                product?.title + " - " + breadCrumb[1].title
          }
        />
        <div
          className={`${
            productStatus(product.status) ? "d-flex" : "d-none"
          } justify-content-center`}
        >
          <br />{" "}
          <h4 style={{ backgroundColor: "#F8F8F8", padding: "25px" }}>
            {t(productStatus(product.status))}
          </h4>
        </div>

        <div className="row ">
          <div className="col-md-12">
            <div className="row g-3">
              <div className="col-md-12 col-lg-6 order-lg-1 order-2">
                <div className="product-content">
                  <div className="wrapped-content">
                    {product && breadCrumb.length !== 0 ? (
                      // <div>
                      <Breadcrumb item={breadCrumb} />
                    ) : // </div>
                    null}

                    <h3>
                      {product && product.title ? product.title : ""}
                      <React.Fragment>
                        {product &&
                          user &&
                          product.user &&
                          product.user._id === user._id && (
                            <Link
                              to={"/edit-ad/" + product.slug}
                              className="btn btn-primary btn-sm"
                              style={{
                                padding: "5px",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              {t("Edit")}
                            </Link>
                          )}
                      </React.Fragment>
                    </h3>

                    <div>
                      <ul className="pricing">
                        <li>
                          {product.is_free || product.price === 0
                            ? t("Free")
                            : locale === "ur_PK"
                            ? urduPricing(product.price, t, settings, locations)
                            : standardPricing(
                                product.price,
                                t,
                                settings,
                                locations
                              )}
                        </li>
                        <li className="reportInline">
                          <ul>
                            <li>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  AddRemoveFav(like);
                                }}
                              >
                                {like ? (
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-heart-o"
                                    aria-hidden="true"
                                  ></i>
                                )}

                                <span> {t("Save")}</span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShow(true);
                                }}
                              >
                                <ShareModel
                                  label={t("Share Listing")}
                                  name={product.title}
                                  link={window.location.pathname}
                                  userImage={
                                    product?.images && product.images[0]
                                  }
                                  productImage={
                                    product?.images && product.images[0]
                                  }
                                />
                              </a>
                            </li>
                            {user &&
                            product?.user &&
                            product.user._id === user._id ? null : (
                              <>
                                <li>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleReportItemModel();
                                    }}
                                  >
                                    <i
                                      className="fa fa-flag"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    <span>{t("Report this item")}</span>
                                  </a>
                                </li>
                              </>
                            )}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="duration-product">
                    <>
                      <p>
                        <span>
                          <span className="font-italic pr-1">
                            {t("Posted")}{" "}
                            {moment(product?.created_at).fromNow()}
                          </span>
                          {product?.state?.state && (
                            <>
                              {" "}
                              -
                              <span className="pl-1">
                                {product?.locality?.locality &&
                                  product?.locality?.locality + ", "}
                                {product?.city?.city}, {product?.state?.state}{" "}
                              </span>
                            </>
                          )}
                        </span>

                        <i className="fa fa-eye productView" aria-hidden="true">
                          {" " + product.view_count}
                        </i>
                      </p>
                    </>
                    {_attributes()}
                    <p>
                      {product?.description?.split("\n")?.map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {item}
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </p>
                    {/* </div> */}
                  </div>
                  {bannerAds?.length > 0 && (
                    <div className="bannerad-detail-page">
                      <BannerAd location="detailPage" />
                    </div>
                  )}
                  {product?.description?.length <= 1800 &&
                    product?.description?.split(/\r\n|\r|\n/)?.length <= 20 && (
                      <div>
                        {((user && product.user._id !== user._id) || !user) && (
                          <div>
                            {chatStatus && product.price !== 0 && (
                              <div className="make-offer-buttons">
                                <a
                                  href="#"
                                  className="btn btn-primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleMakeOffer();
                                  }}
                                >
                                  {t("Make an Offer")}
                                </a>
                              </div>
                            )}

                            <ChatBox
                              product={product}
                              createConversation={createConversation}
                              setMessage={setMessage}
                              message={message}
                              chatStatus={chatStatus}
                              setChatStatus={setChatStatus}
                            />
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div
                className="col-md-12 col-lg-6 order-lg-2 order-1"
                onClick={scrollTop}
              >
                <div className="image-gallery-div">
                  <ImagesSlider
                    style={{ Height: "100%" }}
                    images={product.images}
                  />
                </div>
                {(product?.description?.length > 1800 ||
                  product?.description?.split(/\r\n|\r|\n/)?.length > 20) && (
                  <div>
                    {((user && product.user._id !== user._id) || !user) && (
                      <div>
                        {chatStatus && product.price !== 0 && (
                          <div className="make-offer-buttons">
                            <a
                              href="#"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleMakeOffer();
                              }}
                            >
                              {t("Make an Offer")}
                            </a>
                          </div>
                        )}
                        <ChatBox
                          product={product}
                          createConversation={createConversation}
                          setMessage={setMessage}
                          message={message}
                          chatStatus={chatStatus}
                          setChatStatus={setChatStatus}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <Map
            state={product?.state?.state}
            city={product?.city?.city}
            locality={product?.locality?.locality}
          />
        </div>
        <ProductsTabs
          similarProducts={similarProducts}
          topProducts={topProducts}
          userProducts={userProducts}
          title={product?.user?.name}
        />
      </div>
    </section>
  ) : product !== "NOTFOUND" ? (
    <div className="d-flex justify-content-center">
      <Loader show="true" />
    </div>
  ) : (
    <NotFound />
  );
}
