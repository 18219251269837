import * as actionTypes from "./../actionTypes";
const websiteBanners = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.WEBSITE_BANNERS:
      return action?.websiteBanners;
    default:
      return state;
  }
};

export default websiteBanners;
