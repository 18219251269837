import React, { useState } from "react";

import { TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { inputValidation } from "../../services/Helpers";
import { Input } from "../common/control/InputField";
import { Controller, useController, useForm } from "react-hook-form";

export default function CategoryAttributeInput({
  data,
  // inputValue,
  error,
  helperText,
  control,
  catAttrFieldName,
  ...props
}) {
  const { t } = useTranslation();
  // const { setValue } = useForm();
  // const [fieldValue, setFieldValue] = useState("");

  const { field } = useController({
    name: catAttrFieldName,
    control,
  });

  let { _id, title, type, value, unit, required, min_length, max_length } =
    data;

  const inputProps = {
    minLength: min_length,
    maxLength: max_length,
  };
  const _inputValidation = inputValidation(type, max_length);
  const _titleToSlug = (title) =>
    title.toString().toLowerCase().replace(" ", "-");
  if (typeof value === "string") {
    value = value?.split(",");
  }

  switch (type) {
    case "options":
      let _options = value ? value?.map((value) => value) : [];

      return (
        <>
          <Autocomplete
            // {...field}
            fullWidth
            options={_options}
            // name={`attributes[${_id}]`}
            // value={fieldValue}
            value={field.value}
            onChange={(event, newValue) => {
              field.onChange(newValue);
              props.setValue(catAttrFieldName, newValue);
              // setFieldValue(newValue);
              // field.
            }}
            getOptionLabel={(attr) => t(attr)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <span>
                    {t(title)}
                    {required ? <span className="text-danger"> *</span> : ""}
                  </span>
                }
                variant="outlined"
                error={error}
                helperText={helperText}
                {...props}
              />
            )}
          />
        </>
      );
    default:
      return (
        <div key={_id} className="filters-input mb-3">
          <Input
            label={
              <span>
                {t(title)}
                {required ? <span className="text-danger"> *</span> : ""}
              </span>
            }
            placeholder={t(title)}
            errors={undefined}
            control={control}
            variant="outlined"
            error={error}
            helperText={helperText}
            {...props}
          />
        </div>
      );
  }
}
