import * as actionTypes from "../actionTypes";
const unread_messages = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UNREADMESSAGES:
      return action?.unreadMessages ? action?.unreadMessages : 0;
    default:
      return state;
  }
};

export default unread_messages;
