import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import Auth from "../../services/UserService";
import { getParsedLocalStorageData } from "../../services/Helpers";
// let timer = Date.now() + 60000 * 4;
import { useTranslation } from "react-i18next";

export default function App(props) {
  const { t } = useTranslation();
  // Renderer callback with condition
  const [startTimer, setStartTimer] = useState(true);
  const [timer, setTimer] = useState(Date.now() + 6000 * 2);

  useEffect(() => {
    if (startTimer) {
      setTimer(Date.now() + 60000 * 4);
    }
  }, [startTimer]);

  const sendSMS = async () => {
    await Auth.doUserRequest("user_service/sendsms", {
      mobile: getParsedLocalStorageData("phone"),
    });
    setTimer(Date.now() + 60000 * 4);
    setStartTimer(true);
  };
  // const sendEmail = async () => {
  //   await Auth.doUserRequest("user_service/register", {
  //     email: localStorage.getItem("email"),
  //   });
  //   toast.success("Verification code send successfully");
  //   setTimer(Date.now() + 60000 * 10);
  //   setStartTimer(true);
  // };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <button
          onClick={(e) => {
            e.preventDefault();
            if (props.isEmail && props.submit) {
              // sendEmail();
              props.submit(props.data);
              setTimer(Date.now() + 60000 * 4);
            } else if (props.submitEmail) {
              props.submitEmail();
              setTimer(Date.now() + 60000 * 4);
            } else {
              sendSMS();
            }
          }}
          className="btn btn-secondary"
        >
          <i class="fa fa-repeat" aria-hidden="true">
            {""}
          </i>{" "}
          {t("Resend")}
        </button>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {t("Resend Code in")} {minutes} {t("minutes")} {t("and")} {seconds}{" "}
          {t("Seconds")}
        </span>
      );
    }
  };

  return (
    <span className="verification-code-msg">
      <Countdown date={timer} renderer={renderer} overtime={true} />
    </span>
  );
}
