import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PasswordModalContent from "../common/PasswordModalContent";
import { loginAction, setUser } from "../../actionCreators";
import { getParsedLocalStorageData } from "../../services/Helpers";
import { toast } from "react-toastify";

export default function App(props) {
  const { t } = useTranslation();
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  const sendSMS = async () => {
    const response = await Auth.Request(
      "user_service/sendsms",
      {
        mobile: getParsedLocalStorageData("phone"),
      },
      "post"
    );

    if (response.success) {
      //   toast.success("verification code send successfully");
      props.showPhone();
    }
  };

  const onSubmit = async (data) => {
    setDisableSubmit(true);
    const response = await Auth.Request(
      "user_service/login-mobile-password",
      {
        mobile: getParsedLocalStorageData("phone"),
        password: data.password,
      },
      "post"
    );
    if (response.success) {
      window.location.reload(false);
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.token));
      dispatch(loginAction());
      dispatch(setUser(response.data));
      props.close();
      if (props?.submit) {
        await props.submit(props.data);
      }else{
        toast.success(t(response.message))
      }
    } else {
      setError({ password: { message: response.message } });
      setDisableSubmit(false);
    }
  };

  return (
    <>
      {props.showLogin && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.showLogin();
          }}
        >
          <i
            className="fa fa-arrow-left"
            style={{ marginBottom: "20px", fontSize: "20px" }}
          ></i>
        </a>
      )}

      <h5>{t("Please enter your password")}</h5>
      <PasswordModalContent
        backAction={props.showPhone}
        submitAction={handleSubmit(onSubmit)}
        disableSubmit={disableSubmit}
        // handleChange={handleChange}
        errors={error || errors}
        register={register}
      />
      <br />
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          sendSMS();
          toast.success("Verification code has been sent successfully");
        }}
        className="forgot-password"
      >
        {t("Forgot Password?")}
      </a>
    </>
  );
}
