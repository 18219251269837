import * as actionTypes from "./../actionTypes";
const cmsSettings = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_CMS_SETTINGS:
      return action.cmsData;
    default:
      return state;
  }
};

export default cmsSettings;
