import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Service from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, TextField } from "@mui/material";
import MetaTitle from "../common/MetaTitle";
import { useTranslation } from "react-i18next";

export default function App(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState("");
  const handleDisabledButton = () => setDisabled((disabled) => !disabled);

  const { register, handleSubmit, setValue, errors, reset, formState } =
    useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const onSubmit = async (data) => {
    handleDisabledButton();
    const response = await Service.Request(
      "user_service/web-name",
      {
        name: data.name,
        EMPID: data.EMPID,
        email: data.email,
        websiteName: data.websiteName,
      },
      "post"
    );
    if (response.success) {
      setSuccess("Thank you for your suggestion!");
      reset();
      handleDisabledButton();
    } else {
      toast.error(t(response.message) || t("An Error Occured"));
      handleDisabledButton();
    }
  };
  return (
    <React.Fragment>
      <MetaTitle title="Name Suggestion" />
      <section className="site-mian">
        <div className="container-fluid">
          <div className="upload-file-main">
            <div className="row">
              <div className="col-md-8 col-xl-6 mx-auto">
                <div className="user-upload-product">
                  <h3>{t("Name Suggestion")}</h3>
                  <p style={{ marginBottom: "0" }}>
                    {t("Suggest a Name for Nextbridge MarketPlace & WIN")}
                  </p>
                  <p style={{ padding: "5px 3px", paddingBottom: "30px" }}>
                    {t(
                      "If your suggested name is chosen, you can win some exciting gift"
                    )}
                  </p>
                  {success ? (
                    <p>{success}</p>
                  ) : (
                    <>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <TextField
                            placeholder={t("Name")}
                            name="name"
                            label={`${t("Name")} *`}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^-_a-z\s]/gi, "")
                                .toString()
                                .slice(0, 30);
                            }}
                            autoComplete="off"
                            fullWidth
                            inputRef={register({
                              required: t("Name is required"),
                              minLength: {
                                value: 2,
                                message: t("Name must be 2 characters long"),
                              },
                            })}
                            error={errors.name && errors.name.message !== ""}
                            helperText={errors.name ? errors.name.message : " "}
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            type="number"
                            placeholder={t("Employee ID")}
                            name="EMPID"
                            label={`${t("Employee ID")} *`}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^0-9\.]+/g, "")
                                .toString()
                                .slice(0, 10);
                            }}
                            autoComplete="off"
                            fullWidth
                            inputRef={register({
                              required: t("Employee ID is required"),
                              minLength: {
                                value: 1,
                                message: t(
                                  "Minimun length for employee ID must be 1 digit long"
                                ),
                              },
                              maxLength: {
                                value: 10,
                                message: t(
                                  "Maximum length for employee ID is 10 digits long"
                                ),
                              },
                            })}
                            error={errors.EMPID && errors.EMPID.message !== ""}
                            helperText={
                              errors.EMPID ? errors.EMPID.message : " "
                            }
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            type="text"
                            id="inputEmail"
                            label={`${t("Email")} *`}
                            variant="outlined"
                            fullWidth
                            className="form-control"
                            placeholder={t("Email address")}
                            name="email"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputRef={register({
                              required: t("Email is required"),
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: t("Invalid email"),
                              },
                            })}
                            error={errors.email && errors.email.message !== ""}
                            helperText={
                              errors.email ? errors.email.message : " "
                            }
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            placeholder={t("Website Name")}
                            name="websiteName"
                            label={`${t("Website Name")} *`}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^-_a-z\s]/gi, "")
                                .toString()
                                .slice(0, 30);
                            }}
                            autoComplete="off"
                            fullWidth
                            inputRef={register({
                              required: t("Website name is required"),
                              minLength: {
                                value: 3,
                                message: t(
                                  "Website name must be 3 characters long"
                                ),
                              },
                            })}
                            error={
                              errors.websiteName &&
                              errors.websiteName.message !== ""
                            }
                            helperText={
                              errors.websiteName
                                ? errors.websiteName.message
                                : " "
                            }
                          />
                        </div>
                        <FormControl
                          className="postButtonmain"
                          variant="outlined"
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            className="postButton"
                            disabled={disabled}
                          >
                            {t("Submit")}
                          </Button>
                        </FormControl>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
