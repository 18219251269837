import io from "socket.io-client";
import { getParsedLocalStorageData } from "../services/Helpers";
let user = getParsedLocalStorageData("user");
const SOCKET_URL = process.env.REACT_APP_SOCKET_URI;
let socket;
const connect = (user) => {
  if (user?._id) {
    return io(SOCKET_URL, {
      path: process.env.REACT_APP_SOCKET_PATH,
      query: `userId=${user._id}`,
    });
  }
};

if (user) {
  socket = connect(user);
}

export { socket, connect };
