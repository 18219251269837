import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../../services/UserService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslation } from "react-i18next";
import MetaTitle from "../../common/MetaTitle";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../actionCreators";
import { PasswordInput } from "../../common/control/PasswordField";

export default function App(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();

  useEffect(() => {
    if (token) {
      dispatch(logoutAction());
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
  }, []);

  const password = useRef({});
  password.current = watch("password", "");
  const { token } = useParams();
  const handleDisabled = () => setDisabled((disabled) => !disabled);
  const onSubmit = async (data) => {
    handleDisabled();
    const response = await Auth.doUserRequest(
      `user_service/reset-password/${token}`,
      {
        new_password: data.password,
      }
    );
    if (response.success) {
      toast.success(t(response.message));
      navigate("/login");
    } else {
      handleDisabled();
      toast.error(t(response.message));
    }
  };
  return (
    <React.Fragment>
      <MetaTitle title="Reset password" />
      <div className="d-flex justify-content-center align-items-center container">
        <div className="col-md-12 col-xl-5">
          <div className="checkout-info-box" style={{ minHeight: "auto" }}>
            <h5>{t("Reset Password")}</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <PasswordInput
                  label={t("Password")}
                  placeholder={t("Password")}
                  errors={errors}
                  {...register("password", {
                    required: t("Password is required"),
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                      message: t(
                        "Password must contain uppercase and lowercase letters and at least one number"
                      ),
                    },
                    minLength: {
                      value: 8,
                      message: t("Password must be 8 characters long"),
                    },
                  })}
                  control={control}
                  {...props}
                />
              </div>
              <div className="form-group">
                <PasswordInput
                  label={t("Confirm password")}
                  placeholder={t("Confirm password")}
                  errors={errors}
                  {...register("password_repeat", {
                    validate: (value) =>
                      value === password.current || t("Passwords do not match"),
                  })}
                  control={control}
                  {...props}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disabled}
              >
                {t("Reset Password")} <i className="fa fa-angle-right"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
