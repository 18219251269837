import React, { useState, useEffect } from "react";
import "./style.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "../Product/add/Loader";

const Carousel = ({ images }) => {
  const [showThumbnails, setShowThumbnails] = useState(true);
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const promises = images.map((imageUrl) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = () => resolve(imageUrl);
        });
      });
      const loaded = await Promise.all(promises);
      setLoadedImages(
        loaded.map((imageUrl) => ({
          original: imageUrl,
          thumbnail: imageUrl,
        }))
      );
    };

    loadImages();
  }, [images]);

  return (
    <>
      {loadedImages.length > 0 ? (
        <ImageGallery
          items={loadedImages}
          showPlayButton={false}
          showFullscreenButton={true}
          showThumbnails={showThumbnails}
          useBrowserFullscreen={true}
          disableThumbnailSwipe={true}
          showIndex={true}
          infinite={false}
          onScreenChange={(check) => {
            setShowThumbnails(!check);
          }}
        />
      ) : (
        <Loader show="true" />
      )}
    </>
  );
};

export default Carousel;
