import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Tabs, Typography, Box, Tab } from "@mui/material";
import ProductsModule from "../../Includes/ProductsModule";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const NavTabs = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [userSelling, setUserSelling] = useState([]);
  const [userSold, setUserSold] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setUserSelling(props.selling);
    setUserSold(props.sold);
  }, [props]);

  return (
    <div className="panelProfilemain">
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <LinkTab
            className="tab"
            label={t("Selling")}
            href="/selling"
            {...a11yProps(0)}
          />
          <LinkTab
            className="tab"
            label={t("Sold")}
            href="/sold"
            {...a11yProps(1)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} className="panelProfile">
        <section className="you-will-like featured-products pt-0">
          <div className="container-fluid px-sm-0">
            {userSelling && userSelling.length > 0 ? (
              <ProductsModule products={userSelling} public={true} />
            ) : (
              <div className="no-product">
                <h6>{t("No selling product")}</h6>
              </div>
            )}
          </div>
        </section>
      </TabPanel>
      <TabPanel value={value} index={1} className="panelProfile">
        <section className="you-will-like featured-products pt-0">
          <div className="container-fluid px-sm-0">
            {userSold && userSold.length > 0 && userSold.length > 0 ? (
              <ProductsModule products={userSold} public={true} />
            ) : (
              <div className="no-product">
                <h6>{t("No product sold")}</h6>
              </div>
            )}
          </div>
        </section>
      </TabPanel>
    </div>
  );
};
export default NavTabs;
