import React from "react";
import AddProduct from "./add_product";

function App() {
  return (
    <React.Fragment>
      <AddProduct />
    </React.Fragment>
  );
}

export default App;
