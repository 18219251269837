import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberWithError } from "libphonenumber-js";

import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";

import { Modal } from "react-bootstrap";
import MobileModalContent from "../common/MobileModalContent";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function App(props) {
  const { t } = useTranslation();
  const history = useNavigate();
  const { handleSubmit, errors } = useForm();
  const [value, setValue] = useState();
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [props]);

  const onSubmit = async (data) => {
    setDisable(true);
    if (value) {
      try {
        const phoneNumber = parsePhoneNumberWithError("+" + value);
        if (!phoneNumber.isValid()) {
          setError(t("Invalid phone number"));
          setDisable(false);
        } else {
          const response = await Auth.Request(
            "user_service/update-mobile",
            {
              mobile: "+" + value,
            },
            "post"
          );
          if (response.success) {
            toast.success(t("Verification code sent successfully"));
            localStorage.setItem("phone", JSON.stringify("+" + value));
            props.showPhoneVerification();
            setDisable(false);
          } else {
            setError(response.message);
            setDisable(false);
          }
        }
      } catch (err) {
        setError(t("Invalid phone number"));
        setDisable(false);
      }
    } else {
      setError(t("Phone number is required"));
      setDisable(false);
    }
  };
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className="p-3 login-with-mobile-popup"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => props.close}
          style={{ borderBottom: "none" }}
        >
          {/* <Modal.Title>{t("Enter Your Phone Number")}</Modal.Title> */}
          <h5>{t("Enter Your Phone Number")}</h5>
        </Modal.Header>
        <Modal.Body>
          <MobileModalContent
            submitAction={handleSubmit(onSubmit)}
            disableSubmit={disable}
            handleChange={setValue}
            error={error}
            value={value}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
