import React from "react";

export default function Map({ state, city, locality = "" }) {
  let src =
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13924168.484388078!2d71.735663!3d31.566563!3m2!1i1024!2i768!4f13.1!4m3!3e0!4m0!4m0!5e0!3m2!1sen!2sus!4v1622055410596!5m2!1sen!2sus";

  if (state && city && locality) {
    src = `https://maps.google.com/maps?q=${locality},%20${city},%20${state}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
  } else if (state && city) {
    src = `https://maps.google.com/maps?q=${city},%20${state}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
  }

  return (
    <>
      <div
        className="col-lg-12 detail-map">
        <iframe
          src={src}
          frameBorder="0"
          style={{ border: 0, width: "100%", height: "100%" }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </>
  );
}
