import * as actionTypes from "./../actionTypes";
const featureProducts = (state = [], action) => {
  switch (action.type) {
    // case actionTypes.GET_USERS:
    //   return Object.assign({}, prevState, {isFetching: true});
    case actionTypes.FETCH_FEATURED:
      return action.featureProducts;
    case actionTypes.ERROR:
      return Object.assign(
        [],
        state,
        { isFetching: false },
        { error: action.error }
      );
    default:
      return state;
  }
};

export default featureProducts;
