import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Service from "../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import LoginAll from "./../../Models";
import { useSelector } from "react-redux";
// import Snackbars from "../../Includes/Snackbar";
import MetaTitle from "../../common/MetaTitle";

import {
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  TextareaAutosize,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Autocomplete from "@mui/lab/Autocomplete";
import Loader from "./Loader";
import CategoryAttributeInput from "../CategoryAttributeInput";
import ProductTips from "../productTips";
import ChooseCategory from "../ChooseCategory";
import { useTranslation } from "react-i18next";
import {
  getParsedLocalStorageData,
  currencyLabel,
} from "../../../services/Helpers";
import { Input } from "../../common/control/InputField";
import { CategoryAttribute } from "../CategoryAttribute";

export default function App(props) {
  // window.scrollTo(0, 0);
  const { t } = useTranslation();
  let token = getParsedLocalStorageData("token");

  const navigate = useNavigate();
  const categories = useSelector((state) => state.categories);
  const locations = useSelector((state) => state?.locations || {});
  const imageLimit = useSelector((state) =>
    state.settings.ad_image_limit ? state.settings.ad_image_limit : 20
  );
  const { settings } = useSelector((state) => ({
    settings: state.settings,
  }));
  const [imagesPath, setImagesPath] = useState([]);
  const [productImage, setproductImage] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [free, setFree] = useState(false);
  const [Selected, setSelected] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [locality, setLocality] = useState();
  const [show, setShow] = useState(false);
  const [cities, setCities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [formdata, setformdata] = useState();
  const [count, setCount] = useState(0);

  // parent child category title
  const [parentCategoryTitle, setParentCategoryTitle] = useState("");
  const [parentCategoryId, setParentCategoryId] = useState("");
  const [childCategoryTitle, setChildCategoryTitle] = useState("");

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  /* For Snack bar implementation*/
  // const [openSnack, setOpenSnack] = useState(false);
  // const handleSnack = () => setOpenSnack((openSnack) => !openSnack);
  // const [snackMessage, setSnackMessage] = useState("");
  // const [snackSuccess, setSnackSuccess] = useState(true);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFree = () => setFree(!free);

  const onSubmit = async (data) => {
    token = getParsedLocalStorageData("token");
    if (token) {
      const response = await Service.Request(
        "posting_service/products/add-product",
        {
          title: data.title,
          description: data.description,
          images: imagesPath,
          is_free: free,
          price: free ? 0 : data.price,
          category: Selected,
          quantity: 1,
          attributes: data.attributes,
          state: state,
          city: city,
          locality: locality ? locality : {},
        },
        "post"
      );

      if (response.success) {
        let categoryObject = {};

        if (childCategoryTitle) {
          categoryObject["subCategory"] = response.data.category;
          categoryObject["category"] = categories.find(
            (c) => c._id === parentCategoryId
          );
          if (categoryObject["category"]) {
            categoryObject["category"] = categoryObject["category"]._id;
          }
        } else {
          categoryObject["category"] = response.data.category;
        }
        // navigate({
        //   pathname: "/ad-posted",
        //   slug: response.data.slug,
        //   productId: response.data._id,
        //   ...categoryObject,
        //   success: response.adPosted,
        // });
        navigate("/ad-posted", {
          state: {
            slug: response.data.slug,
            productId: response.data._id,
            ...categoryObject,
            success: response.adPosted,
          },
        });
        toast.success(t(response.message));
      } else {
        toast.error(t(response.message) || t("Network error"));
      }
    } else {
      setformdata(data);
      setShow(true);
    }
  };

  useEffect(() => {
    if (imagesPath.length == 0) {
      setValue("image", "");
    }
  }, [imagesPath]);

  const handleCategory = (e, v) => {
    if (!v) return;

    let { _id } = v;

    findCategory(categories, _id);
    setSelected(_id);
  };

  const handleStateLocation = (event, value) => {
    if (value?.state) {
      const state = {
        _id: value?._id,
        state: value?.state,
      };
      setState(state);
      if (errors.state?.message) {
        errors.state.message = "";
      }
      setLocalities([]);
      setCities(value?.cities);
    }
  };
  const handleCityLocation = (event, value) => {
    if (value?.city) {
      const city = {
        _id: value?._id,
        city: value?.city,
      };
      setCity(city);
      if (errors.city?.message) {
        errors.city.message = "";
      }
      setLocalities(value?.localities);
    }
  };
  const handleLocalityLocation = (event, value) => {
    if (value?.locality) {
      const locality = {
        _id: value?._id,
        locality: value?.locality,
      };
      setLocality(locality);
      if (errors.locality?.message) {
        errors.locality.message = "";
      }
    }
  };

  const delete_image = async (index) => {
    const response = await Service.Request(
      `posting_service/products/delete-image/${productImage[index]["id"]}`,
      {},
      "delete"
    );
    if (response.success) {
      // setSnackMessage(t(response.message));
      // setSnackSuccess(response.success);
      // handleSnack();
      setproductImage(productImage.filter((item, i) => i != index));
      setImagesPath(imagesPath.filter((item, i) => i != index));
    } else {
      // toast.success(t(response.message));
    }
  };

  const handleChange = async (event) => {
    let length = imageLimit - productImage.length;
    let files = event.target.files;
    let all_paths = [];
    let all_products = [];
    // setImagesLength(state.imagesLength + 1);
    setShowLoader(true);
    let counter = 0;
    for (var i = 0; i < length + counter; i++) {
      let formData = new FormData();
      let imageFile = files[i];
      if (imageFile) {
        if (!imageFile.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG)$/)) {
          counter++;

          // toast.error(`${imageFile.name} is not a valid image`);
          toast.error("Selected file is not a valid image");
          setShowLoader(false);
          if (imagesPath.length === 0) {
            setValue("image", "");
          }
          return false;
        } else {
          formData.append("image", files[i]);
          const response = await Service.UploadImage(
            "posting_service/products/upload-image",
            formData,
            "POST",
            token
          );

          if (response.success) {
            all_paths.push(response.segmented_path);
            all_products.push({
              id: response.id,
              path: response.path,
              segmented_path: response.segmented_path,
            });
          } else {
            toast.error(t(response.message));
            counter++;
          }
        }
      }
    }
    all_paths = imagesPath.concat(all_paths);
    all_products = productImage.concat(all_products);

    setImagesPath(all_paths);
    setproductImage(all_products);
    setShowLoader(false);
  };

  let ImagesList = productImage.map(function (product, index) {
    return (
      <li key={index}>
        <img className="col-sm-12" src={product.path} />
        <i className="fa fa-trash" onClick={() => delete_image(index)}></i>
      </li>
    );
  });

  let findCategory = function (data, _id) {
    return data.some(function (e) {
      if (e._id == _id) {
        setSelectedCategory(e);
        return true;
      } else if (e.catChilds) return findCategory(e.catChilds, _id);
    });
  };

  const appendSubcats = (category) => {
    let htmlData = category.catChilds.map(function (subCat, index) {
      return (
        <>
          <option value={subCat._id}>{"-- " + subCat.title}</option>
        </>
      );
    });

    htmlData = <optgroup label={category.title}>{htmlData}</optgroup>;

    return htmlData;
  };

  let renderSubCategories = categories.map(function (category, index) {
    if (category.parent_category) {
      return;
    }

    let subCatsData = "";

    if (category.catChilds.length > 0) {
      subCatsData = appendSubcats(category);
    } else {
      subCatsData = <option value={category._id}>{category.title}</option>;
    }

    return subCatsData;
  });
  return (
    <React.Fragment>
      {/* <Snackbars
        message={snackMessage}
        success={snackSuccess}
        open={openSnack}
        close={handleSnack}
      /> */}
      <MetaTitle title="Ad Post" />
      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <LoginAll
          data={formdata}
          quickPost={true}
          submit={onSubmit}
          close={handleClose}
        />
      </Modal>

      <section className={"site-mian " + `${!Selected ? "d-none" : ""}`}>
        <div className="container-fluid">
          <div className="upload-file-main">
            <div className="row">
              <div className="col-lg-8 col-xl-7 mx-auto mb-lg-0 mb-5">
                <h3>{t("Post Your Ad")}</h3>
                <p>
                  {t("Details with")} <span className="red-clr">*</span>{" "}
                  {t(
                    "are required, other details are not compulsory but you will greatly improve the response to your advert if you include them"
                  )}
                </p>

                <div className="user-upload-product">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="mb-2">{t("SELECTED CATEGORY")}</h4>
                    <div className="breadcrums">
                      <ul>
                        {parentCategoryTitle && (
                          <li>
                            <a href="#">{t(parentCategoryTitle)}</a>
                          </li>
                        )}

                        {childCategoryTitle && (
                          <li>
                            <a href="#">{t(childCategoryTitle)}</a>
                          </li>
                        )}
                      </ul>
                      <a
                        title={t("Change")}
                        className="change"
                        onClick={(e) => {
                          setSelected("");
                          setChildCategoryTitle("");
                          setParentCategoryTitle("");
                          setParentCategoryId("");
                        }}
                      >
                        {t("Change")}
                      </a>
                    </div>
                    <h5>{t("Photos")}</h5>
                    <div className="uploaded-images">
                      <ul>{ImagesList}</ul>
                      <p>
                        <b>{t("Tip")}</b>:{" "}
                        {t("Add at least 3 images for better response")}
                      </p>
                      <p>{t("Images must be in PNG and JPG format")}</p>
                    </div>
                    <div className="form-group uploaded-images-btn-main mb-4">
                      <label htmlFor="thefile">
                        <Button
                          title={t("Upload")}
                          disabled={
                            productImage.length >= imageLimit ? true : false
                          }
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          className="uploadBtn"
                        >
                          {t("Upload")}
                        </Button>
                      </label>
                      {imagesPath.length > 0 ? null : (
                        <span id="fileName">{t("No file selected")}</span>
                      )}

                      {productImage.length >= imageLimit ? null : (
                        <input
                          className="d-none"
                          accept=".png,.jpg,.jpeg"
                          id="thefile"
                          type="file"
                          multiple
                          {...register("image", {
                            required: t("Upload at least one image"),
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        />
                      )}

                      <Loader show={showLoader} />
                      {errors.image &&
                      errors.image.message !== "" &&
                      productImage.length === 0 ? (
                        <span className="error-message-ag mb-2">
                          <span>*</span> {t(errors.image.message)}
                        </span>
                      ) : (
                        productImage.length >= imageLimit && (
                          <span className="error-message-ag">
                            {t("only") +
                              " " +
                              t(imageLimit) +
                              " " +
                              t("images are allowed")}
                          </span>
                        )
                      )}
                    </div>

                    <div className="form-group">
                      <Input
                        {...register("title", {
                          required: t("Title is required"),
                          minLength: {
                            value: 5,
                            message: t("Title must be 5 characters long"),
                          },
                        })}
                        label={t("Title") + " *"}
                        placeholder={t("Title")}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .toString()
                            .replace(/^\s/g, "")
                            .slice(0, 70);
                        }}
                        error={errors.title && errors.title.message !== ""}
                        // helperText={errors.title ? errors.title.message : " "}
                        errors={errors}
                        control={control}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        errors={errors}
                        {...register("description", {
                          required: t("Description is required"),
                          minLength: {
                            value: 5,
                            message: t(
                              "Description must be at least 5 characters long"
                            ),
                          },
                        })}
                        rows={4}
                        multiline
                        className="form-control"
                        label={t("Description") + " *"}
                        placeholder={t("Description")}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/^\s+/g, "");
                        }}
                        error={Boolean(
                          errors.description && errors.description.message
                        )}
                        control={control}
                        onChange={(e) => setCount(e.target.value.length)}
                        inputProps={{
                          maxLength: parseInt(
                            settings?.ad_description_limit,
                            10
                          ),
                        }}
                        // style={{ height: "100px" }} // Set the height here
                      />

                      <p
                        style={{
                          textAlign: "right",
                          color: "#A0A0A0",
                          marginTop: "-20px",
                        }}
                      >
                        {count}/{settings?.ad_description_limit}
                      </p>
                    </div>
                    {selectedCategory
                      ? selectedCategory.attributes.map((attribute, i) => {
                          const {
                            title,
                            required,
                            type,
                            max_length,
                            min_length,
                          } = attribute;
                          let _rules = {};
                          let _name = attribute.title
                            .toString()
                            .toLowerCase()
                            .replace(" ", "-");

                          if (required) {
                            _rules["required"] = t(`${title} is required`);
                          }
                          if (title.toLowerCase() == "year") {
                            _rules["minLength"] = {
                              value: 4,
                              message: t("Year should be 4 digits long"),
                            };
                            _rules["maxLength"] = {
                              value: 4,
                              message: t("Year should be 4 digits long"),
                            };
                          } else {
                            _rules["minLength"] = {
                              value: min_length,
                              message: t(
                                `${title} should be ${min_length} digits long`
                              ),
                            };
                            _rules["maxLength"] = {
                              value: max_length,
                              message: t(
                                `${title} should be ${max_length} digits long`
                              ),
                            };
                          }
                          let _catAttrFieldName = `attributes[${attribute._id}]`;

                          return (
                            <div
                              className="form-group makeDropdown"
                              style={{ marginBottom: "1.2rem" }}
                            >
                              <CategoryAttribute
                                catAttrFieldName={_catAttrFieldName}
                                attribute={attribute}
                                errors={errors}
                                control={control}
                                // rules={_rules}
                                setValue={setValue}
                                {...register(_catAttrFieldName, { ..._rules })}
                              />
                            </div>
                          );
                        })
                      : null}
                    <div className="form-group is-free-form">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={free}
                            onChange={handleFree}
                            color="primary"
                            name="is_free"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            className="giveitAway"
                          />
                        }
                      />
                      <span className="is_free">
                        {t("Give it away for free")}
                      </span>
                    </div>
                    {free ? (
                      <b />
                    ) : (
                      <div>
                        <FormControl fullWidth variant="outlined">
                          <Controller
                            name="price"
                            control={control}
                            defaultValue=""
                            rules={{ required: t("Price is required") }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="EnterPrice"
                                type="number"
                                label={
                                  <span>
                                    {t("Price")}
                                    <span className="text-danger"> *</span>
                                  </span>
                                }
                                style={{ margin: "0 0 20px 0", border: "none" }}
                                placeholder={t("Price")}
                                fullWidth
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .replace(/[^0-9\s]/, "")
                                    .toString()
                                    .slice(0, 15);
                                }}
                                margin="normal"
                                autoComplete="off"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {t(
                                        currencyLabel(
                                          locations?.currencySymbol ||
                                            settings?.default_currency
                                        )
                                      )}
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    min: 1,
                                  },
                                }}
                                variant="outlined"
                                error={!!errors.price}
                                helperText={
                                  errors.price ? errors.price.message : " "
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    )}
                    <div className="form-group">
                      <Autocomplete
                        noOptionsText={t("Select State")}
                        id="states_listing"
                        options={locations?.states || []}
                        getOptionLabel={(locations) => {
                          if (locations?.translation?.length > 0) {
                            return locations?.translation[0].labelTranslation;
                          }
                          return locations?.state ? locations?.state : "";
                        }}
                        fullWidth
                        onChange={handleStateLocation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                {t("Select State")}
                                <span className="text-danger"> *</span>
                              </span>
                            }
                            variant="outlined"
                            // name="state"
                            autoComplete="off"
                            value={locations?.state?._id}
                            {...register("state", {
                              required: t("Select one option"),
                            })}
                            error={errors.state && errors.state.message !== ""}
                            helperText={
                              errors.state ? errors.state.message : " "
                            }
                          />
                        )}
                      />
                    </div>
                    {cities?.length > 0 && (
                      <>
                        <div className="form-group">
                          <Autocomplete
                            id="cities_listing"
                            options={cities}
                            noOptionsText={t("Select City")}
                            getOptionLabel={(city) => {
                              if (city?.translation?.length > 0) {
                                return city?.translation[0].labelTranslation;
                              }
                              return city?.city ? city.city : "";
                            }}
                            fullWidth
                            onChange={handleCityLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("Select City")} *`}
                                variant="outlined"
                                autoComplete="off"
                                {...register("city", {
                                  required: t("Select one option"),
                                })}
                                error={
                                  errors.city && errors.city.message !== ""
                                }
                                helperText={
                                  errors.city ? errors.city.message : " "
                                }
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                    {localities && localities?.length !== 0 ? (
                      <>
                        <div className="form-group">
                          <Autocomplete
                            id="localities_listing"
                            options={localities}
                            noOptionsText={t("Select Locality")}
                            getOptionLabel={(locality) => {
                              if (locality?.translation?.length > 0) {
                                return locality?.translation[0]
                                  .labelTranslation;
                              }
                              return locality?.locality
                                ? locality.locality
                                : "";
                            }}
                            fullWidth
                            onChange={handleLocalityLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("Select Locality")} *`}
                                variant="outlined"
                                autoComplete="off"
                                {...register("locality", {
                                  required: t("Select one option"),
                                })}
                                error={
                                  errors.locality &&
                                  errors.locality.message !== ""
                                }
                                helperText={
                                  errors.locality
                                    ? errors.locality.message
                                    : " "
                                }
                              />
                            )}
                          />
                        </div>
                      </>
                    ) : null}
                    <FormControl className="postButtonmain" variant="outlined">
                      <Button
                        title={t("Post")}
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className="postButton"
                      >
                        {t("Post")}
                      </Button>
                    </FormControl>
                  </form>
                </div>
              </div>
              <ProductTips />
            </div>
          </div>
        </div>
      </section>
      {!Selected && (
        <ChooseCategory
          categories={categories}
          setParentCategoryTitle={setParentCategoryTitle}
          setChildCategoryTitle={setChildCategoryTitle}
          setParentCategoryId={setParentCategoryId}
          handleCategory={handleCategory}
        />
      )}
    </React.Fragment>
  );
}
