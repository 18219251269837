import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Popover, Button, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Sidebar from "./sidebar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutModule from "./logged-out";
import { socket, connect } from "../../services/Socket";
import { getUnreadMessages, setSelectedCategory } from "../../actionCreators";
import Service from "../../services/UserService";
import {
  prepareFiltersURL,
  getRouteParams,
  getSelectedCategory,
} from "../../services/Helpers";
import LocationFilters from "../Product/locationFilters";
import { useSettingsUIContext } from "../../SettingUIContext";
import { useTranslation } from "react-i18next";
import LanguagesDropDown from "./dropdown/LanguagesDropdown";
import DropDown from "./dropdownn";
import { BannerAd } from "../BannerAd/BannerAd";
import { getParsedLocalStorageData } from "../../services/Helpers";
import { setUser } from "../../actionCreators";

export default function Navbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { category_slug } = useParams();

  const { loggedIn, categories, unreadMessages, user, settings, bannerAds } =
    useSelector(
      (state) => ({
        loggedIn: state.loggedIn,
        categories: state.categories,
        unreadMessages: state.unreadMessages,
        user: state.user,
        settings: state.settings,
        bannerAds: state.bannerAd,
      }),
      shallowEqual
    );

  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("all");
  const [isOpen, setIsOpen] = useState(false);
  let [slug, setSlug] = useState();

  const [socketInstance, setSocketInstance] = useState(socket);
  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      show_mobile_app_icon: settingsUIContext.show_mobile_app_icon,
      android_mobile_app_url: settingsUIContext.android_mobile_app_url,
      color: settingsUIContext.color,
      hover: settingsUIContext.hover,
    };
  }, [settingsUIContext]);

  const handleShow = () => setIsOpen((isOpen) => !isOpen);
  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (settingsUIProps.color && settingsUIProps.hover) {
      document.documentElement.style.setProperty(
        "--primary",
        settingsUIProps?.color
      );
      document.documentElement.style.setProperty(
        "--primaryhover",
        settingsUIProps?.hover
      );
    }
  }, [settingsUIProps]);

  useEffect(() => {
    setSlug(
      window.location.pathname.substring(
        window.location.pathname.lastIndexOf("/") + 1
      )
    );

    if (!location.pathname.includes("category")) {
      setSearch("");
      dispatch(setSelectedCategory("all"));
    }
  }, [location]);

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("newConversation", async (data) => {
        updateMessagesCounter();
      });
    }
  }, [socketInstance]);

  useEffect(() => {
    async function fetchUser() {
      const response = await Service.Request("user_service/user", {}, "GET");
      if (response.success) {
        const updatedObject = { ...user, ...response.data };
        dispatch(setUser(updatedObject));
        localStorage.setItem("user", JSON.stringify(updatedObject));
      }
    }
    if (localStorage.getItem("token")) {
      fetchUser();
    }
  }, []);

  const [anchorEl, setAnchorE1] = React.useState(null);
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  function toJSON(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query?.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i]?.split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return "";
  }

  useEffect(() => {
    let slug = location?.pathname?.split("/");
    let category = categories.filter((category) => category.slug === slug[2]);
    if (category.length > 0) setCategory(toJSON(category[0]));
    let searchText = getQueryVariable("search")
      ? getQueryVariable("search")
      : "";
    setSearch(searchText);
  }, [categories]);

  const updateMessagesCounter = async () => {
    const unreadData = await Service.Request(
      `chat_service/unread-messages`,
      {},
      "GET"
    );
    if (getParsedLocalStorageData("token")) {
      dispatch(getUnreadMessages(unreadData.data));
    }
  };
  if (user && user?._id && !socketInstance) {
    setSocketInstance(connect(user));
    updateMessagesCounter();
  }
  const handleSearch = (e) => {
    let searchInput = e.target.value ? e.target.value : search;
    let categoryObject;
    let slug = location?.pathname?.split("/");
    if (slug[2]) {
      slug = slug[2].substr(0, (slug[2] + "?").indexOf("?"));
    }
    categoryObject = getSelectedCategory(categories, slug);
    const cat_slug = categoryObject?.slug ? categoryObject?.slug : "all";
    let _filterUrl = {
      pathname: `/category/${cat_slug}`,
      search: `${prepareFiltersURL([{ search: searchInput }])}${
        location?.search?.includes("page") ? "" : "&page=1"
      }`,
    };

    navigate(getRouteParams(_filterUrl, categoryObject));

    setAnchorE1(null);
  };
  const goToLogin = () => {
    navigate("/login");
  };

  const goToPost = () => {
    navigate("/post-ad");
  };
  const goToHome = () => {
    navigate("/");
  };

  const goToChat = () => {
    navigate("/chat");
    // window.location.reload(false);
  };
  const goToProfile = () => {
    handleClose();
    navigate("/profile");
  };
  const boughtPackages = () => {
    handleClose();
    navigate("/purchased/packages");
  };
  const packageOptions = () => {
    handleClose();
    navigate("/package/options");
  };
  const goToSettings = () => {
    handleClose();
    navigate("/setting");
  };
  window.addEventListener("storage", (e) => {
    if (e.key === "token" && e.oldValue && !e.newValue) {
      window.location.reload(false);
    }
  });

  return (
    <React.Fragment>
      <LogoutModule open={isOpen} close={close} />
      {bannerAds?.length > 0 && (
        <div className="bannerad-header">
          <BannerAd location="header" />
        </div>
      )}
      {loggedIn ? (
        <div className="grow">
          {user?.adminLogin && (
            <span className="loggedInAsUser">{t("Loggedin as User")}</span>
          )}
          <AppBar position="fixed" id="back-to-top-anchor">
            <Toolbar className="toolbar">
              <Sidebar />
              <Link className="navbar-brand" to="/">
                <Typography className="title" variant="h6" noWrap>
                  <img
                    className="brand"
                    src={settings?.websiteLogo}
                    alt={settings?.website_title}
                  />
                </Typography>
              </Link>

              <div className="search-header">
                <div>
                  <DropDown />
                </div>
                <div className="locationHeader">
                  <LocationFilters showCity={false} />
                </div>
                <div className="searchInner">
                  <InputBase
                    title={t("Search")}
                    placeholder={`${t("Search")}…`}
                    className="bgSizing"
                    inputProps={{ "aria-label": "search" }}
                    value={search}
                    variant="outlined"
                    autoComplete="off"
                    name="search"
                    onChange={(e) => {
                      if (
                        (e.target.value !== "" &&
                          e.target.value.trim() !== "") ||
                        e.nativeEvent.inputType === "deleteContentBackward" ||
                        e.nativeEvent.inputType === "deleteContentForward"
                      ) {
                        setSearch(e.target.value);
                      }
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        handleSearch(ev);
                      }
                    }}
                  />
                  <Button
                    title={t("Search")}
                    className="searchButton"
                    // disabled={!search.trim()}
                    onClick={handleSearch}
                  >
                    <SearchIcon className="whiteIcon" />
                  </Button>
                </div>
              </div>
              <div className="imgSectionDesktop">
                {settingsUIProps.show_mobile_app_icon && (
                  <div className="img">
                    <a href={settingsUIProps.android_mobile_app_url}>
                      <img
                        title={t("Download Mobile app")}
                        src="/images/products/download-mobile.png"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                )}
                <div className="mainDesktop">
                  <div className="sectionDesktop">
                    <div className="left">
                      <Button
                        title={t("Post")}
                        color="inherit"
                        className="buttonPost"
                        onClick={goToPost}
                      >
                        {" "}
                        <CameraAltIcon className="loggedCameraIcon" />{" "}
                        {t("Post")}
                      </Button>
                    </div>
                    <div className="right">
                      <IconButton
                        aria-label="show 4 new mails"
                        color="inherit"
                        onClick={goToChat}
                      >
                        <Badge
                          title={t("Chat")}
                          badgeContent={unreadMessages ? unreadMessages : 0}
                          max={99}
                          color="secondary"
                        >
                          <MessageIcon className="iconColors" />
                        </Badge>
                      </IconButton>
                      <IconButton
                        aria-label="show 17 new notifications"
                        color="inherit"
                        onClick={goToHome}
                        className="d-none"
                      >
                        <Badge color="secondary">
                          <NotificationsIcon className="iconColors" />
                        </Badge>
                      </IconButton>
                      <IconButton
                        title={t("My Profile")}
                        edge="end"
                        aria-haspopup="true"
                        color="inherit"
                        aria-describedby={id}
                        onClick={handleClick}
                      >
                        <Avatar
                          className="iconColors profileIcon"
                          src={user ? user?.image : "/images/avatar.jpg"}
                        />
                        {/* <AccountCircle /> */}
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <MenuItem title={t("Profile")} onClick={goToProfile}>
                          {t("Profile")}
                        </MenuItem>
                        <MenuItem
                          title={t("Packages")}
                          // className="d-none"
                          onClick={boughtPackages}
                        >
                          {t("Packages")}
                        </MenuItem>
                        <MenuItem
                          title={t("Buy Packages")}
                          // className="d-none"
                          onClick={packageOptions}
                        >
                          {t("Buy Packages")}
                        </MenuItem>

                        <MenuItem title={t("Settings")} onClick={goToSettings}>
                          {t("Settings")}
                        </MenuItem>

                        <MenuItem
                          title={t("Logout")}
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow();
                          }}
                        >
                          {t("Logout")}
                        </MenuItem>
                      </Popover>
                    </div>
                  </div>
                  <div className="langDropdown">
                    <LanguagesDropDown />
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      ) : (
        <AppBar position="fixed" id="back-to-top-anchor">
          <Toolbar className="toolbar">
            <Sidebar />
            <Link className="navbar-brand" to="/">
              <Typography className="title" variant="h6" noWrap>
                <img
                  src={settings?.websiteLogo}
                  alt={settings?.website_title}
                />
              </Typography>
            </Link>

            <div className="search-header">
              <div>
                <DropDown />
              </div>
              <div className="locationHeader">
                <LocationFilters showCity={false} />
              </div>
              <div className="searchInner">
                <InputBase
                  title={t("Search")}
                  placeholder={`${t("Search")}…`}
                  className="bgSizing"
                  inputProps={{ "aria-label": "search" }}
                  value={search}
                  variant="outlined"
                  autoComplete="off"
                  name="search"
                  onChange={(e) => {
                    if (
                      (e.target.value !== "" && e.target.value.trim() !== "") ||
                      e.nativeEvent.inputType === "deleteContentBackward" ||
                      e.nativeEvent.inputType === "deleteContentForward"
                    ) {
                      setSearch(e.target.value);
                    }
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      handleSearch(ev);
                    }
                  }}
                />
                <Button
                  title={t("Search")}
                  className="searchButton"
                  disabled={!search.trim()}
                  onClick={handleSearch}
                >
                  <SearchIcon className="whiteIcon" />
                </Button>
              </div>
            </div>
            <div className="imgSectionDesktop">
              {settingsUIProps.show_mobile_app_icon && (
                <div className="img">
                  <a href={settingsUIProps.android_mobile_app_url}>
                    <img
                      title={t("Download Mobile App")}
                      src="/images/products/download-mobile.png"
                      className="img-fluid"
                    />
                  </a>
                </div>
              )}

              <div className="mainDesktop">
                <div className="sectionDesktop">
                  <Button
                    title={t("Post")}
                    data-tip
                    data-for="postAdd"
                    variant="outlined"
                    className="btnPosts"
                    onClick={goToPost}
                  >
                    {" "}
                    <CameraAltIcon className="cameraIcon" /> {t("Post")}
                  </Button>
                  {/* <ReactTooltip id="postAdd" place="top" effect="solid">
                    Tooltip for the register button
                  </ReactTooltip> */}

                  <Button
                    title={t("Login")}
                    data-tip
                    data-for="registerTip"
                    variant="outlined"
                    className="loginbutton"
                    onClick={goToLogin}
                  >
                    <i className="fa fa-key"></i>
                    {t("Login")}
                  </Button>
                  {/* <ReactTooltip id="registerTip" place="top" effect="solid">
                    Tooltip for the register button
                  </ReactTooltip> */}
                </div>

                <div className="langDropdown">
                  <LanguagesDropDown />
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      )}
    </React.Fragment>
  );
}
