// import { takeEvery, takeLatest, throttle } from "redux-saga";
import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";

export function* getCmsPages() {
  try {
    let cmsSetting = yield call(() =>
      Service.Request("posting_service/cms-pages", "", "GET").then(
        (response) => {
          return response?.data;
        }
      )
    );
    yield put(actionCreators.setCmsPages(cmsSetting));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
