import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actionCreators";
import Service from "./../services/UserService";

export function* getBannerAds() {
  try {
    let bannerAds = yield call(() =>
      Service.Request("listing_service/banner/ads", "", "GET").then(
        (response) => {
          return response.banners;
        }
      )
    );
    yield put(actionCreators.getBannerAds(bannerAds));
  } catch (error) {
    yield put(actionCreators.error(error));
  }
}
