import React from "react";
import { useTranslation } from "react-i18next";
import { categoryLabelTranslations } from "../../services/Helpers";

export default function ChooseCategory({
  categories,
  handleCategory,
  setParentCategoryTitle,
  setChildCategoryTitle,
  setParentCategoryId,
}) {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  return (
    <div>
      <section className="categories_list">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h3>{t("POST YOUR AD")}</h3>
              </div>
            </div>
          </div>
          <div className="categories_list_box">
            <div className="row mx-auto">
              <div className="col-12 px-0">
                <h5>{t("CHOOSE A CATEGORY")}</h5>
              </div>
              <div className="col-md-6 col-sm-6 col-4 px-0">
                <div className="tabsLeft">
                  <ul className="nav nav-tabs">
                    {categories?.map((category, index) => {
                      return (
                        <li key={index}>
                          <a
                            title={categoryLabelTranslations(category)}
                            href={"#" + category?.slug}
                            onClick={(e) => {
                              setParentCategoryId(category._id);
                              if (category?.catChilds?.length === 0) {
                                setParentCategoryTitle(
                                  categoryLabelTranslations(category)
                                );
                                handleCategory(e, category);
                              } else {
                                setParentCategoryTitle(
                                  categoryLabelTranslations(category)
                                );
                                e.preventDefault();
                              }
                              categories.length == index + 1 &&
                                window.scrollTo({
                                  top: 150,
                                  left: 150,
                                  behavior: "smooth",
                                });
                              // setCategorySelected(true);
                            }}
                            data-toggle="tab"
                          >
                            <span>{categoryLabelTranslations(category)}</span>
                            {category?.catChilds?.length > 0 && (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-8 px-0">
                <div className="tabsRight">
                  <div className="right-sub-categories">
                    <div className="tab-content">
                      {categories?.map((category, index) => {
                        return (
                          <div className="tab-pane" id={category?.slug}>
                            <ul>
                              {category?.catChilds?.map(
                                (childCategory, index) => {
                                  return (
                                    <li key={index}>
                                      <a
                                        title={categoryLabelTranslations(
                                          childCategory
                                        )}
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setChildCategoryTitle(
                                            categoryLabelTranslations(
                                              childCategory
                                            )
                                          );
                                          handleCategory(e, childCategory);
                                        }}
                                      >
                                        <span>
                                          {categoryLabelTranslations(
                                            childCategory
                                          )}
                                        </span>
                                      </a>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="main" className="categories_list_box_accordion">
            <div className="container">
              <div className="row">
                <div className="col-12 px-0">
                  <h5>{t("CHOOSE A CATEGORY")}</h5>
                </div>
                <div className="accordionLeft">
                  <ul id="faq">
                    {categories?.map((category, index) => {
                      return (
                        <li className="card" key={index}>
                          <div
                            className="card-header"
                            id={category?.slug + "head"}
                          >
                            <a
                              href="#"
                              onClick={(e) => {
                                if (category?.catChilds?.length === 0) {
                                  setParentCategoryTitle(
                                    categoryLabelTranslations(category)
                                  );
                                  handleCategory(e, category);
                                } else {
                                  setParentCategoryTitle(
                                    categoryLabelTranslations(category)
                                  );
                                  e.preventDefault();
                                }
                              }}
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target={"#" + category?.slug + "small"}
                              aria-expanded="false"
                              aria-controls={category?.slug + "small"}
                            >
                              <span>{categoryLabelTranslations(category)}</span>
                              {category?.catChilds?.length > 0 && (
                                <i className="fa fa-angle-down"></i>
                              )}
                            </a>
                          </div>

                          <div
                            id={category?.slug + "small"}
                            className="collapse show"
                            aria-labelledby={category?.slug + "head"}
                            data-parent="#faq"
                          >
                            <div className="card-body p-0">
                              <div className="accordionRight">
                                <ul>
                                  {category?.catChilds?.map((childCategory) => {
                                    return (
                                      <li>
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setChildCategoryTitle(
                                              categoryLabelTranslations(
                                                childCategory
                                              )
                                            );
                                            handleCategory(e, childCategory);
                                          }}
                                        >
                                          <span>
                                            {categoryLabelTranslations(
                                              childCategory
                                            )}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
