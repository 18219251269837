import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  prepareFiltersURL,
  getRouteParams,
  getAllQuery,
} from "../../services/Helpers";
import { useTranslation } from "react-i18next";
import { setLocality } from "../../actionCreators";
import { useSettingsUIContext } from "../../SettingUIContext";

export default function LocationFilters({
  category,
  showCity = true,
  marginBottom = "0",
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCategory, locations } = useSelector(
    (state) => ({
      selectedCategory: state.selectedCategory,
      locations: state?.locations?.states || [],
    }),
    shallowEqual
  );

  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      default_country: settingsUIContext.default_country,
    };
  }, [settingsUIContext]);
  const [stateId, setStateId] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [localities, setLocalities] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const navigate = useNavigate();
  const _queryParams = getAllQuery(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!_queryParams["state"]) {
      dispatch(setLocality(settingsUIProps.default_country || "Pakistan"));
      return;
    }

    const selectedLocation = locations.find(
      (location) => location._id === _queryParams["state"]
    );
    if (selectedLocation) {
      setSelectedLocation(selectedLocation);
      setCities(selectedLocation.cities);

      if (!_queryParams["city"] && !_queryParams["locality"]) {
        dispatch(setLocality(selectedLocation.state));
      }
    } else if (!stateId) {
      setSelectedLocation(null);
      setCities([]);
    }
  }, [locations, stateId]);

  useEffect(() => {
    if (_queryParams["city"] && cities && !selectedCity) {
      const foundCity = cities.find(
        (city) => city._id === _queryParams["city"]
      );
      if (foundCity) {
        setSelectedCity(foundCity);
        setLocalities(foundCity.localities);
        if (!_queryParams["locality"]) {
          dispatch(setLocality(foundCity.city));
        }
      }
    }
  }, [cities]);

  useEffect(() => {
    if (_queryParams["locality"] && localities && localities.length > 0) {
      const selectedLocality = localities.find(
        (locality) => locality._id === _queryParams["locality"]
      );
      if (selectedLocality) {
        setSelectedLocality(selectedLocality);
        dispatch(setLocality(selectedLocality.locality));
      }
    }
  }, [localities]);

  useEffect(() => {
    const changedState = getAllQuery(true)["state"];
    setStateId(changedState);
  }, [searchParams]);
  // useEffect(() => {
  //   const unsubscribe = navigate((location) => {
  //     handleHistoryChange(location); // Call the handler with the current location
  //   });

  //   return unsubscribe; // Unsubscribe on unmount
  // }, [navigate, handleHistoryChange]);

  let _cat = selectedCategory;
  const slug = _cat?.slug ? _cat.slug : _cat;

  const handleStatesLocations = (event, value) => {
    if (value?._id) {
      setSelectedLocation(value);
      setSelectedCity(null);
      setCities(value?.cities);
      dispatch(setLocality(value?.state));
    } else {
      setSelectedLocation(null);
      setSelectedCity(null);
      setCities([]);
    }
    searchParams.set("state", value?._id ? value?._id : "");
    searchParams.set("city", "");
    searchParams.set("locality", "");
    if (window.location.pathname.includes("category")) {
      setSearchParams(searchParams);
    } else {
      navigate(`/category/all?${searchParams.toString()}`);
    }
    setLocalities([]);
  };

  const handleCitiesLocation = (event, value) => {
    if (value?._id) {
      setSelectedCity(value);
      setSelectedLocality("");
      dispatch(setLocality(value?.city));
    } else {
      setSelectedCity("");
      setSelectedLocality("");
      dispatch(setLocality(selectedLocation?.state));
    }
    searchParams.set("city", value?._id ? value?._id : "");
    searchParams.set("locality", "");
    setSearchParams(searchParams);
    // navigate(getRouteParams(_filterUrl, _cat));
    setLocalities(value?.localities);
  };

  const handleLocalityLocation = (event, value) => {
    if (value?._id) {
      setSelectedLocality(value);
      dispatch(setLocality(value?.locality));
    } else {
      setSelectedLocality("");
      dispatch(setLocality(selectedCity?.city));
    }
    searchParams.set("locality", value?._id ? value?._id : "");
    setSearchParams(searchParams);
  };
  return (
    <React.Fragment>
      {!showCity && (
        <Autocomplete
          // id="states_listing"
          style={{ width: 160 }}
          className="select-state-style"
          options={locations}
          value={selectedLocation}
          // inputValue={"Pakistan"}
          getOptionLabel={(locations) => {
            if (locations?.translation?.length > 0) {
              return (
                locations?.translation[0]?.labelTranslation || locations?.state
              );
            }
            return locations?.state;
          }}
          onChange={handleStatesLocations}
          renderInput={(params) => (
            <TextField
              {...params}
              title={t("Select Location")}
              label={t("Select Location")}
              variant="outlined"
              name="state"
              size="small"
              autoComplete="off"
              value="Pakistan"
              // InputProps={{
              //   startAdornment: <LocationOnOutlinedIcon />,
              // }}
            />
          )}
        />
      )}
      {showCity && (
        <Autocomplete
          // id="states_listing"
          style={{ width: 250, marginBottom: marginBottom }}
          options={locations}
          value={selectedLocation}
          // open={true}
          getOptionLabel={(locations) => {
            if (locations?.translation?.length > 0) {
              return (
                locations?.translation[0]?.labelTranslation || locations?.state
              );
            }
            return locations?.state;
          }}
          onChange={handleStatesLocations}
          renderInput={(params) => (
            <TextField
              {...params}
              title={t("Select State")}
              label={t("Select State")}
              variant="outlined"
              name="state"
              size="small"
              autoComplete="off"
            />
          )}
        />
      )}
      {showCity && cities?.length > 0 && (
        <Autocomplete
          id="cities_listing"
          className="ml-2 select-state-style"
          style={{ width: 250, marginBottom: marginBottom }}
          value={selectedCity || null}
          key={cities}
          options={cities}
          getOptionLabel={(city) => {
            if (city?.translation?.length > 0) {
              return city?.translation[0]?.labelTranslation || city?.city;
            }
            return city?.city;
          }}
          onChange={handleCitiesLocation}
          renderInput={(params) => (
            <TextField
              {...params}
              title={t("Select City")}
              label={t("Select City")}
              variant="outlined"
              name="city"
              size="small"
              autoComplete="off"
            />
          )}
        />
      )}
      {showCity && localities?.length > 0 && (
        <Autocomplete
          id="localities_listing"
          style={{ width: 250, marginBottom: marginBottom }}
          className="ml-2 select-state-style"
          options={localities}
          value={selectedLocality || null}
          getOptionLabel={(locality) => {
            if (locality?.translation?.length > 0) {
              return (
                locality?.translation[0]?.labelTranslation || locality?.locality
              );
            }
            return locality?.locality;
          }}
          onChange={handleLocalityLocation}
          renderInput={(params) => (
            <TextField
              {...params}
              title={t("Select Locality")}
              label={t("Select Locality")}
              variant="outlined"
              size="small"
              name="locality"
              autoComplete="off"
            />
          )}
        />
      )}
    </React.Fragment>
  );
}
