import React from "react";
import Login from "./login";

function App() {
  return (
    <React.Fragment>
      <Login />
    </React.Fragment>
  );
}

export default App;
