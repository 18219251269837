import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import VerificationModalContent from "../common/VerificationModalContent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
export default function App(props) {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state);
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useNavigate();
  const { handleSubmit, errors } = useForm();
  const [error, setError] = useState("");
  const [value, setValue] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleChange = (otp) => {
    setValue(otp);
    if (otp.length === 6) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  };

  const onSubmit = async (data) => {
    user.mobile_number = JSON.parse(localStorage.getItem("phone"));
    setDisableSubmit(true);
    const response = await Auth.Request(
      "user_service/verify-updated-mobile",
      {
        mobile: JSON.parse(localStorage.getItem("phone")),
        code: value,
      },
      "post"
    );
    if (response.success) {
      await Auth.asyncLocalStorage.setItem(
        "user",
        JSON.stringify(response.data)
      );

      toast.success(t("Mobile updated successfully"));

      props.close();
    } else {
      setError(response.message);
      setDisableSubmit(false);
    }
  };
  return (
    <React.Fragment>
      <Modal
        className="icon-change profile-icon-change"
        show={props.show}
        onHide={props.onHide}
        centered
      >
        <div className="modal-content login-with-mobile-popup">
          <div className="modal-body" style={{ padding: "0" }}>
            <div
              className="d-flex justify-content-center align-items-center container"
              style={{ padding: "0" }}
            >
              <div className="col-md-12 col-xl-12" style={{ padding: "0" }}>
                <button type="button" className="close" onClick={props.close}>
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">Close</span>
                </button>
                <div
                  className="checkout-info-box"
                  style={{ minHeight: "auto", marginBottom: "0" }}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      props.showPhone();
                    }}
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{
                        marginBottom: "20px",
                        fontSize: "18px",
                        position: "relative",
                        left: "-20px",
                      }}
                    ></i>
                  </a>

                  <h5>{t("Please verify your phone number")}</h5>

                  <VerificationModalContent
                    backAction={props.showPhone}
                    submitAction={handleSubmit(onSubmit)}
                    disableSubmit={disableSubmit}
                    handleChange={handleChange}
                    error={error}
                    value={value}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
