import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonContent from "./buttonContent";
import { toast } from "react-toastify";
import Service from "../../../services/UserService";
import { useSelector } from "react-redux";
import { currencyLabel } from "../../../services/Helpers";

export default function App(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const locations = useSelector((state) => state?.locations || {});
  const { settings } = useSelector((state) => ({
    settings: state.settings,
  }));
  let currencySymbol = t(
    currencyLabel(locations?.currencySymbol || settings?.default_currency)
  );
  const { productId, category, subCategory } = location;
  const buyPackage = async () => {
    const response = await Service.Request(
      "posting_service/user-package/ad/More_ad",
      { category, subCategory, productId, singlePackage: true },
      "POST"
    );
    if (response.success) {
      toast.success(response.message);
      navigate("/profile");
    } else {
      toast.error(response.message);
    }
  };
  const [postPackage, setPostPackage] = useState(null);
  const getPackages = async () => {
    if (category && subCategory) {
      const response = await Service.Request(
        `/listing_service/packages?category=${category}&&subCategory=${subCategory}&&type=More_ad`,
        {},
        "GET"
      );
      if (response.success) {
        if (response.data.length > 0 && response.data[0]?.packages.length > 0) {
          setPostPackage(response.data[0]?.packages[0]);
        }
      }
    }
  };

  useEffect(() => {
    getPackages();
  }, [location]);

  return (
    <React.Fragment>
      <section className="site-mian">
        <div className="container-fluid">
          <div className="post-submitted-content">
            <div className="row w-100">
              <div className="col-lg-12 col-xl-12 mb-lg-0 mb-md-3 mx-auto">
                <div className="post-submitted-content mb-md-0 mb-3 d-flex align-items-center">
                  <h3 className="text-danger">
                    {t("Your ad was not posted")}!
                  </h3>
                  <p>{t("You have exceeded the free limit ad quota")}</p>
                </div>
                {postPackage && (
                  <div className="post-featured-option mt-5">
                    <div className="row align-items-center mt-5">
                      <div className="col-md-12">
                        <h4>{t("What next ?")}</h4>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="featured-pkg-box another-post-ad">
                          <div>
                            <h4 className="mb-2">{t("Post another Ad")}</h4>
                            <h5 className="mb-0">
                              <strong>
                                {currencySymbol} {postPackage?.price}
                              </strong>
                            </h5>
                          </div>
                          <div>
                            <button
                              onClick={() => buyPackage()}
                              className="btn-primary btn"
                            >
                              {t("Post your Ad")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <ButtonContent />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
