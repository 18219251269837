import { all } from "redux-saga/effects";
import { getCategories } from "./getcategries";
import { getUnreadMessages } from "./getunreadmessages";
import { getLocations } from "./getLocations";
import { getAllSettings } from "./getAllSettings";
import { getLanguages } from "./getLanguages";
import { getTranslations } from "./getTranslations";
import { getCmsPages } from "./getCmsPages";
import { getBannerAds } from "./getBannerAds";
import { getWebsiteBanners } from "./getWebsiteBanner";

export default function* rootSaga() {
  yield all([
    getCategories(),
    getUnreadMessages(),
    getAllSettings(),
    getLanguages(),
    getTranslations(),
    getCmsPages(),
    getBannerAds(),
    getLocations(),
    getWebsiteBanners(),
  ]);
}
