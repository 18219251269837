import * as actionTypes from "./../actionTypes";
const category_list = (state = [], action) => {
  switch (action.type) {
    // case actionTypes.GET_USERS:
    //   return Object.assign({}, prevState, {isFetching: true});
    case actionTypes.FETCH_CATEGORIES:
      return action.categories;
    case actionTypes.ERROR:
      return Object.assign(
        [],
        state,
        { isFetching: false },
        { error: action.error }
      );
    default:
      return state;
  }
};

export default category_list;
