import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { useDispatch } from "react-redux";
import { loginAction, setUser } from "../../actionCreators";
import { useTranslation } from "react-i18next";
import VerificationModalContent from "./../common/VerificationModalContent";
import { getParsedLocalStorageData } from "../../services/Helpers";
import { toast } from "react-toastify";

export default function App(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState("");
  const [value, setValue] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const handleChange = (otp) => {
    setValue(otp);
    if (otp.length === 6) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  };

  const onSubmit = async (data) => {
    setDisableSubmit(true);
    const response = await Auth.doUserRequest("user_service/verify-code", {
      mobile: getParsedLocalStorageData("phone"),
      code: value,
    });
    if (response.success) {
      await Auth.asyncLocalStorage.setItem(
        "user",
        JSON.stringify(response.data)
      );
      await Auth.asyncLocalStorage.setItem(
        "token",
        JSON.stringify(response.token)
      );
      dispatch(loginAction());
      dispatch(setUser(response.data));
      props.close();
      if (props?.submit) {
        await props.submit(props.data);
      }else{
        toast.success(t(response.message))
      }
    } else {
      setError(response.message);
      setDisableSubmit(false);
    }
  };

  return (
    <React.Fragment>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          props.showPhone();
        }}
      >
        <i
          className="fa fa-arrow-left"
          style={{ marginBottom: "20px", fontSize: "18px",position: "relative",left: "-20px" }}
        ></i>
      </a>
      <h5>{t("Please verify your phone number")}</h5>
      <VerificationModalContent
        backAction={props.showPhone}
        submitAction={handleSubmit(onSubmit)}
        disableSubmit={disableSubmit}
        handleChange={handleChange}
        error={error}
        value={value}
      />
    </React.Fragment>
  );
}
