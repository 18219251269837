import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Service from "./services/UserService";
import { getParsedLocalStorageData } from "./services/Helpers";

let translations = getParsedLocalStorageData("translations");
let locale = Service.getLocale();
locale = locale || localStorage.getItem("fallbackLanguage");
setTranslation(locale, translations);

function setTranslation(locale, translations) {
  // const translationObject =
  if (locale && translations) {
    i18n
      .use(Backend)
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        fallbackLng: localStorage.getItem("defaultLocale"),
        react: {
          useSuspense: false,
        },
        resources: {
          [locale]: translations[locale],
        },

        detection: {
          order: [
            "querystring",
            "localStorage",
            "path",
            "htmlTag",
            "subdomain",
          ],
          // keys or params to lookup language from
          lookupQuerystring: "locale",
          // lookupLocalStorage: "defaultLocale",
          // lookupFromSubdomainIndex: 0,
        },
        // debug: true,
      });
  } else {
    i18n
      .use(Backend)
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        fallbackLng: localStorage.getItem("fallbackLanguage"),
        react: {
          useSuspense: false,
        },
        resources: {},

        detection: {
          order: [
            "querystring",
            "localStorage",
            "path",
            "htmlTag",
            "subdomain",
          ],
          // keys or params to lookup language from
          lookupQuerystring: "locale",
          // lookupLocalStorage: "defaultLocale",
          // lookupFromSubdomainIndex: 0,
        },
        // debug: true,
      });
  }
}

export default i18n;
